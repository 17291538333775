import React, {useState, useEffect} from "react";
import {Table, Button, message, Popconfirm, Space, Tooltip} from 'antd';
import api from "../api";
import User from "../helpers/User";
import {OrgLink} from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";
import {DeleteOutlined, EyeOutlined} from "@ant-design/icons";
import { CreatorTag } from "../explore/CreatorTagList";
import { Can } from "../helpers/Can";
import TableDataDownloadButton from "../widgets/TableDataDownloadButton";
import PaginationTotal from "../widgets/PaginationTotal";

export default ({q, onLoaded, refresh, newCreatorTag}) => {
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState({});

    const columns = [
        {
            title: t('name','Name'),
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: t('linked-user','Linked User'),
            dataIndex: 'linked_user',
            render: (user) => user ? <User user={user} /> : null,
        },
        {
            title: t('assets','Assets'),
            dataIndex: 'assets_count',
            sorter: true,
            render: (count, record) => (
                <OrgLink to={`/explore?creator_tag_id[]=${record.id}`}>{count}</OrgLink>
            ),
        },
        {
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (tag) => {
                return (
                    <>
                        <Space size={5}>
                            <Can I={'edit'} a={'CreatorTag'}>
                                <Space direction={'horizontal'}>
                                    <CreatorTag cTag={tag} afterInfoUpdate={fetchData} trigger={'click'}>
                                        <Tooltip title={t('details','Details')}>
                                            <Button icon={<EyeOutlined/>} size={'small'} className={'view-button'}/>
                                        </Tooltip>
                                    </CreatorTag>

                                    <Popconfirm
                                        title={t('are-you-sure-delete-creator-tag', 'Are you sure you want to delete this creator tag?')}
                                        onConfirm={() => handleDelete(tag.id)}
                                        okText={t('yes', 'Yes')}
                                        cancelText={t('no', 'No')}
                                    >
                                        <Tooltip title={t('delete', 'Delete')}>
                                            <Button icon={<DeleteOutlined />} size={'small'} danger className={'delete-button'}/>
                                        </Tooltip>
                                    </Popconfirm>
                                </Space>
                            </Can>
                        </Space>
                    </>
                );
            }
        },
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        const newSettings = {pagination, filters, sorter, q};
        setSettings(newSettings);
        fetchData({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
        });
    };

    const fetchData = (params = {}) => {
        setLoading(true);
        api.get('/api/creator_tags', {
            params: {
                ...params,
                q,
            },
        }).then(response => {
            setData(response.data);
            setPagination({
                ...params.pagination,
                total: parseInt(response.headers['total-entries'], 10),
            });
            setLoading(false);
            onLoaded();
        });
    };

    const handleDelete = (id) => {
        api.delete(`/api/creator_tags/${id}`).then(() => {
            message.success(t('creator-tag-deleted','Creator tag deleted successfully'));
            fetchData(pagination);
        }).catch(() => {
            message.error(t('error-deleting-creator-tag','Error deleting creator tag'));
        });
    };

    useEffect(() => {
        fetchData({pagination});
    }, [q, refresh, newCreatorTag]);

    return (
        <Table
            size="small"
            bordered
            columns={columns}
            rowKey={record => record.id}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            footer={() => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Space>
                        <TableDataDownloadButton apiPath="/api/creator_tags" settings={settings} />
                    </Space>
                    <PaginationTotal pagination={pagination} />
                </div>
            )}
        />
    );
};
import React, {useState, useContext, useEffect, useRef, useCallback, useLayoutEffect, Fragment} from "react";

import filesize from "filesize";
import {
    Popover,
    Tree,
    Empty,
    message,
    Button,
    Typography,
    Descriptions,
    Space,
    Popconfirm,
    Modal,
    Tooltip,
    Tag,
    Skeleton,
    Row,
    Col,
    List,
    Input as AntInput,
    Card,
    Dropdown,
    Menu,
    Progress,
    Alert, Collapse, Divider, Badge, Checkbox, Flex,
} from 'antd';

import api from "../api";
import {
    ContainerFilled,
    DeleteOutlined,
    EditFilled,
    EditOutlined,
    FolderAddFilled,
    FolderAddOutlined,
    FolderFilled,
    FolderOpenOutlined,
    InfoCircleOutlined,
    LockOutlined,
    PlusOutlined,
    SaveOutlined,
    SearchOutlined,
    SettingOutlined,
    CheckOutlined,
    EyeOutlined,
    BranchesOutlined,
    FolderOutlined,
    ShareAltOutlined,
    LoadingOutlined,
    ApiOutlined,
    CloudOutlined,
    DropboxOutlined,
    CloseOutlined,
    EyeInvisibleOutlined,
    GoogleOutlined,
    ControlOutlined,
    DownloadOutlined
} from "@ant-design/icons";
import TimeAgo from "../helpers/TimeAgo";
import {Formik, useFormikContext} from "formik";
import * as Yup from "yup";
import {Form, FormItem, Input, AutoComplete} from "formik-antd";
import FloatLabel from "../helpers/FloatLabel";

import pluralize from 'pluralize'
import User from "../helpers/User";
import {useStorageState} from "react-storage-hooks";
import {useDrop} from "react-dnd";
import {AbilityContext, Can} from "../helpers/Can";
import {
    ArchiveIcon,
    BinIcon, CloneIcon,
    CollectionIcon, FrameIcon, GoogleDriveIcon, GreyBadge,
    LightboxIcon, LightroomClassicIcon, LightroomIcon,
    OrganizerIcon,
    ProjectIcon, ShareIcon,
    StorageFolderIcon, UserGroupIcon, WatermarkIcon,
    WorkflowIcon
} from "../helpers/icons";
import {useAggsState} from "../../contexts/AggsContext";
import {useSelectedAggsState} from "../../contexts/SelectedAggsContext";
import {useSelectedAssetsDispatch, useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import {useAssetGroupDispatch, useAssetGroupState} from "../../contexts/AssetGroupContext";
import {useAssetsDispatch} from "../../contexts/AssetsContext";

import {useNavigate, useLocation} from "react-router-dom-v5-compat";
import LockFilled from "@ant-design/icons/lib/icons/LockFilled";
import {useBulkJobsDispatch, useBulkJobsState} from "../../contexts/BulkJobsContext";
import Links from "../widgets/Links";

import {
    isMobile
} from "device-detect";
import {useOrgPath} from "../helpers/OrgNavLink";
import useCurrentOrg from "../helpers/useCurrentOrg";
import DownloadButton, {BoxFolderPicker, DropboxFolderPicker, GoogleDriveFolderPicker} from "../explore/DownloadButton";
import HelpPopover from "../HelpPopover";
import useConsumer from "../../channels/consumer";
import LightroomConnectionButton from "../widgets/LightroomConnectionButton";
import useCurrentUser, {useTimezone} from "../helpers/useCurrentUser";
import AssetGroupLink from "../widgets/AssetGroupLink";
import AssetGroupChooser from "./AssetGroupChooser";
import moment from 'moment-timezone';
import {useTranslation} from "react-i18next";
import VerticalSpace from "~/components/helpers/VerticalSpace";
import WorkflowForm from "@/components/manage/WorkflowForm";
import async from "async";
import {ChildrenSortFormItem} from "~/components/widgets/AssetGroupDrawer";

const TreeContext = React.createContext(null);

// --- Extract:
function updateTreeData(list, key, children) {
    if(!key) return children;

    // Recursively find matching node by key, update children:
    return list.map(node => {
        if (node.key === key) {
            if(children.length) node.isLeaf = false;
            node.loaded = true;
            return { ...node, children };
        }

        if (node.children) {
            return { ...node, children: updateTreeData(node.children, key, children) };
        }

        return node;
    });
}

const updateAssetGroup = (id, type, sub_type, parent_sub_type, data, cb) => {
    const route = {
        'Collection': 'collections',
        'StorageFolder': 'storage_folders',
        'Lightbox': 'lightboxes'
    }[type];

    const field = {
        'Collection': 'collection',
        'StorageFolder': 'storage_folder',
        'Lightbox': 'lightbox'
    }[type];

    let params = {sub_type, parent_sub_type};
    params[field] = data;

    api.put(`/api/${route}/${id}`,params).then(cb).catch(error => {
        message.error('Sorry, you do not have permission to update this.')
        cb && cb(false)
    })
}

const getNodeFromDatum = (c, organizerSelection, selectableIf, checkableIf) => {
    return {
        id: c.id,
        key: c.slug,
        type: c.type,
        subType: c.sub_type,
        name: c.name,
        parentId: c.parent_id,
        assetGroup: c,
        selectable: (!c.organizer || !!organizerSelection) && (!selectableIf || selectableIf(c)),
        checkable: (!checkableIf || checkableIf(c)),
        hasChildren: c.has_children,
        isLeaf: !c.has_children,
        title: () => (
            <NodeTitle
                c={c}
                onUpdate={(data) => updateAssetGroup(c.id, c.type, c.sub_type, null, data, (success)=>{ success && message.success('Name updated.') }) }
            />
        ),
    };
}

/**
 *
 * @param {string} type
 * @param {string} fieldName
 * @param {boolean} multiple
 * @param {any} reset change this state variable to trigger a collection tree reload
 */

export default ({type='collections', current, setSelectedGroup, fieldName,
                    multiple, reset, sandbox, draggable=true, enableAssetDrop=false,
                    sessionKey, showAggs, organizerSelection, selectableIf, checkableIf, dimIf, hideCountIf, hideInfo, showBadge,
                    readOnly, hideAddButton, hideRearrangeButton, hideViewAll, showAggsCount}) => {

    const {t} = useTranslation();

    const typeClass = {
        'collections': 'Collection',
        'lightboxes': 'Lightbox',
        'storage_folders': 'StorageFolder',
        'access_requests': 'AccessRequest',
    }[type];

    const typeName =  {
        'collections': t('collections','Collections'),
        'lightboxes': t('lightboxes','Lightboxes'),
        'storage_folders': t('storage-folders','Storage Folders')
    }[type];

    if(current && current.type !== typeClass) current = null;

    let setFieldValue, values, selectedGroups;
    if(fieldName) {
        const context = useFormikContext();
        setFieldValue = context.setFieldValue;
        values = context.values;
        selectedGroups = current ? [current.folder?.slug || current.slug] : [values[fieldName]];
    } else {
        selectedGroups = [current?.folder?.slug || current?.slug];
    }

    const {allAssetCounts, total: totalAssets} = useAggsState();

    const assetsDispatch = useAssetsDispatch();
    const selectedAssetsDispatch = useSelectedAssetsDispatch()
    const {dragging} = useSelectedAssetsState()

    const {viewAllType, newAssetGroup} = useAssetGroupState();
    const assetGroupDispatch = useAssetGroupDispatch();

    useEffect(()=>{
        if(newAssetGroup?.type !== typeClass) return

        const node = getNodeFromDatum(newAssetGroup, organizerSelection, selectableIf, checkableIf);

        const {parent} = newAssetGroup
        if(parent) {
            loadTreeData({key: parent.slug, subType: parent.sub_type, force: true}, ()=> {
                expand(null, {expanded: true, node: parent});
            });
        } else {
            treeData.unshift(node);
        }

        setTreeData([...treeData]);

        assetGroupDispatch({type:'newAssetGroupAdded'})
    }, [newAssetGroup?.id])

    const [treeData, setTreeData] = useState([]);

    const [loading, setLoading] = useState(true);

    const loadingKeys = useRef([])

    const loaderQueue = useRef(
        async.queue((args, cb)=>{
            return loadTreeDataWorker(args, cb)
        })
    );

    const loadTreeData = (args, cb)=> {
        return loaderQueue.current.push(args, cb)
    }

    const loadTreeDataWorker = ({id, key, children, subType, force}, cb) => {
        console.log('loadTreeDataWorker', id, key)
        return new Promise(resolve => {
            if (children || (!force && loadedKeys.indexOf(key) !== -1)) {
                cb && cb()
                resolve();
                return;
            }

            // TODO: post all expandedKeys, get back full tree structure:

            if(loadingKeys.current.indexOf(key) !== -1) return resolve()
            loadingKeys.current = [...loadingKeys.current, key]

            const data = []

            const loadPage = page => {
                api(`/api/${type}/tree`, { params: { sandbox, parent_id: id, sub_type: subType, page } }).then(res => {
                    data.push(...res.data)

                    if(parseInt(res.headers['total-pages']) > page) loadPage(page + 1)
                    else proceed()
                })
            }

            const proceed = ()=> {
                if(!key) setLoading(false)

                loadingKeys.current = _.without(loadingKeys.current, key)

                const newNodes = data.map((d) => {
                    window.assetGroupSlugs[d.asset_group_id] = d.slug;
                    window.assetGroupIds[d.slug] = d.asset_group_id;

                    return getNodeFromDatum(d, organizerSelection, selectableIf, checkableIf);
                });
                setTreeData(origin => updateTreeData(origin, key, newNodes));

                setLoadedKeys(loadedKeys => _.uniq(loadedKeys.concat(key)))

                const done = ()=>{
                    resolve();
                    cb && cb();
                }

                if(key) {
                    // Check each node for existence in expanded keys, expand if present:
                    asyncForEach(data, async ({id, sub_type, slug}) => {
                        if(expandedKeysRef.current.indexOf(slug) !== -1) {
                            await loadTreeData({id: id, key: slug, subType: sub_type}, ()=> {
                                expand(null, {expanded: true, node: {key: slug}});
                            })
                        }
                    }, done)
                } else {
                    done()
                }
            }

            loadPage(1)
        });
    }

    // --- Extract:
    async function asyncForEach(array, callback, done) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
        done();
    }

    const loadNew = (force)=>{
        setLoading(true)
        // load top level (null key), then ancestor IDs down the tree
        if(force || !current || !current.path_slugs?.length) {
            loadTreeData({key: null, force});
        } else {
            // load top level (null key), then ancestor IDs down the tree
            asyncForEach([null].concat(current.path_slugs), async (slug) => {
                await loadTreeData({key: slug, id: assetGroupIds[slug]});
            }, () => {
                setExpandedKeys(current.path_slugs)
            })
        }
    }

    useEffect(()=>{
        if(reset) {
            setLoadedKeys([])
            setTreeData([])
            loadNew(true)
        } else {
            loadNew()
        }
    },[reset])

    const [checkedKeys, setCheckedKeys] = useState([])
    const [checkedIds, setCheckedIds] = useState([])

    const onCheck = (keys) => {
        setCheckedKeys(keys.checked)
        setCheckedIds(keys.checked.map(slug => assetGroupIds[slug]))
        console.log('checked',keys, multiple)

        if(!multiple) return;
        setFieldValue && setFieldValue(fieldName, keys)
    }

    const [selectedKeys, setSelectedKeys] = useState(selectedGroups);

    // Ensure correct `current` group is selected:
    useEffect(()=>{
        setSelectedKeys(selectedGroups)
    }, [current?.id])

    // Update based on current (may be in a different chooser):
    useEffect(()=>{
        if(!showAggs) return

        if(viewAllType === typeClass) setSelectedKeys(['_all']);
        else if(viewAllType === `No${typeClass}`) setSelectedKeys(['_none']);
        else selectedGroups?.length && setSelectedKeys(selectedGroups);
    }, [...selectedGroups, viewAllType])


    const onSelect = (keys, e) => {
        if(multiple) return;

        setSelectedKeys([e.node.assetGroup?.slug || e.node.slug]);

        setFieldValue && setFieldValue(fieldName, e.node.assetGroup.asset_group_id);
        setSelectedGroup && setSelectedGroup(e.node.assetGroup || e.node.viewAllType);
    }

    // --- Extract:
    const onDrop = (info) => {
        if(info.node.assetGroup?.publish_type === 'lightroom') {
            return message.error(t('error-cannot-add-folders-to-lightroom-publish-connection','Folders cannot be manually added to Lightroom Publish Connections.'))
        }

        if(!info.dragNode) {
            return message.error(t('error-edit-mode-drag-drop-disabled','Currently in Edit Mode, Asset drag/drop disabled.'))
        }

        if(info.dragNode.assetGroup?.publish_type === 'lightroom') {
            return message.error(t('error-no-folder-moving-from-lightroom-publish-connection','Folders cannot be manually moved in Lightroom Publish Connections.'))
        }

        // Disallow lightbox dragging out of Project:
        if(info.dragNode.type === 'Lightbox' && info.dragNode.assetGroup.project_id) {
            return message.error(t('error-no-moving-lightboxes-out-of-project', `A Lightbox within a project cannot be moved out, please clone it instead.`));
        }

        // Disallow Bin dragging out of Lightbox:
        if(
            info.dragNode.assetGroup.sub_type === 'folder' &&
            (info.dragNode.assetGroup.folder_root_id !== info.node.assetGroup.folder_root_id ||
                info.node.assetGroup.sub_type === 'project' ||
                info.node.assetGroup.organizer
            )
        ) {
            return message.error(t('error-no-moving-folders-out-of-lightbox', `A Folder within a Lightbox cannot be moved out, please clone it instead.`));
        }

        // Disallow Lightbox dragging onto Lightbox:
        if(
            info.node.assetGroup && (info.dragNode.type === 'Lightbox' &&
            info.dragNode.assetGroup.sub_type !== 'folder' &&
            info.node.assetGroup.type === 'Lightbox' &&
            !info.node.assetGroup.organizer &&
            (!info.node.assetGroup.sub_type?.length && info.node.assetGroup.sub_type !== 'home')
        )) {
            return message.error(t('error-lightbox-cannot-be-moved-to-different-project', `A Lightbox cannot be placed within another Lightbox.`));
        }

        if(info.dragNode.assetGroup?.organizer && !info.node.assetGroup?.organizer) {
            return message.error(t('error-organizers-only-dropped-into-organizers', `An Organizer can only be dropped into another organizer.`));
        }

        // only allow drop on another node, since alpha ordering
        if(info.node.key === '_all' || info.node.key === '_none' || info.dropToGap && info.node.parentId === info.dragNode.parentId) return;

        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        let parent;
        const loop = (data, key, callback) => {
            data.forEach((item, index, arr) => {
                if (item.key === key) {
                    return callback(item, index, arr, parent);
                }
                if (item.children) {
                    parent = item;
                    return loop(item.children, key, callback);
                }
            });
        };

        setTreeData(treeData => {
            const newRawData = [...treeData];

            // Find dragObject
            let dragObj;
            loop(newRawData, dragKey, (item, index, arr, parent) => {
                arr.splice(index, 1);
                dragObj = item;
                if(parent) parent.isLeaf = !arr.length;
            });

            if (!info.dropToGap) {
                // Drop on the content
                loop(newRawData, dropKey, item => {
                    item.children = item.children || [];
                    // where to insert
                    item.children.push(dragObj);
                    item.isLeaf = false;
                    dragObj.parentId = item.id;
                });
            } else if (
                (info.node.children || []).length > 0 && // Has children
                info.node.expanded && // Is expanded
                dropPosition === 1 // On the bottom gap
            ) {
                loop(newRawData, dropKey, item => {
                    item.children = item.children || [];
                    // where to insert
                    item.children.unshift(dragObj);
                });
            } else {
                let ar;
                let i;
                let dropItem;
                loop(newRawData, dropKey, (item, index, arr) => {
                    dropItem = item;
                    ar = arr;
                    i = index;
                });
                dragObj.parentId = dropItem?.id;
                if (dropPosition === -1) {
                    ar?.splice(i, 0, dragObj);
                } else {
                    ar?.splice(i + 1, 0, dragObj);
                }
            }

            return newRawData;
        })

        const newParentId = info.dropToGap ? info.node.parentId : info.node.id;

        updateCounts(info.dragNode, info.node)

        updateAssetGroup(info.dragNode.id, info.dragNode.type, info.dragNode.subType, info.node.subType, {parent_id: newParentId || 0}, (res) => {
            if(!res) return;

            assetsDispatch({type:'reload'})
            selectedAssetsDispatch({type:'reload'});
            message.success(`${info.dragNode.name} ${t('moved', 'moved')}.`);
        })
    };

    const updateCounts = (dragNode, node)=> {
        setTreeData(treeData => {
            const findNode = (lb) => {
                const traverse = (nodes) => {
                    if (!nodes) return;

                    let found;
                    for (let node of nodes) {
                        if (lb.asset_group_id === node.assetGroup?.asset_group_id) return node;

                        found = traverse(node.children)
                        if (found) return found;
                    }
                }

                return traverse(treeData)
            };

            // Add counts to new parent nodes:
            const count = dragNode.assetGroup.visible_assets_count
            const size = dragNode.assetGroup.visible_assets_size

            let parent = node.assetGroup;
            while(parent) {
                parent.visible_assets_count += count
                parent.visible_assets_size += size
                parent = findNode({asset_group_id: parent.parent_id})?.assetGroup
            }

            // Remove counts from old parent nodes:
            parent = findNode({asset_group_id: dragNode.assetGroup.parent_id})?.assetGroup
            while(parent) {
                parent.visible_assets_count -= count
                parent.visible_assets_size -= size
                parent = findNode({asset_group_id: parent.parent_id})?.assetGroup
            }

            return [...treeData]
        })
    }

    const key = `assetGroupChooser-${type}-${sessionKey}`
    const [expandedKeys, setExpandedKeys] = useStorageState(sessionStorage, key, []);

    // Ref for queue worker to always have latest value:
    const expandedKeysRef = useRef(expandedKeys);

    const [loadedKeys, setLoadedKeys] = useState([]);

    const expand = (keys, {expanded, node})=> {
        setExpandedKeys(expandedKeys => {
            const newValue = _.uniq(expanded ? expandedKeys.concat(node.key) : _.without(expandedKeys, node.key))
            expandedKeysRef.current = newValue
            return newValue
        });
    }

    // -----------------------
    // Mode
    // -----------------------
    const [mode, setModeState] = useStorageState(sessionStorage, `AssetGroupChooserMode-${type}-${sessionKey}`, null);

    const editMode = mode === 'Edit';

    const setMode = (newMode) => {
        if (newMode != '') message.info(t('message-asset-container-mode-activated', '{{type}} {{mode}} Mode Activated.', {type: typeName.toProperCase(), mode: t(newMode.toLowerCase(), newMode)}));
        setModeState(newMode);
    };
    // -----------------------

    //------------------------
    // Search
    //------------------------

    const [searchExpandedKeys, setSearchExpandedKeys] = useState([]);

    const [searching, setSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const searchValue = useRef();

    const apiSearch = _.debounce((value)=>{
        searchValue.current = value

        api(`/api/${type}`, { params: { q: value, sandbox: sandbox } }).then(res => {
            if(searchValue.current != value) return;

            setLoading(false);
            const newNodes = res.data.map((d) => getNodeFromDatum(d,organizerSelection, selectableIf));
            setSearchResults(newNodes);
        });
    }, 250);

    const search = (e)=> {
        const { value } = e.target;

        if(value && value != '') {
            setSearching(true);
            setLoading(true);
            apiSearch(value)
        } else {
            setSearching(false);
        }
    };

    const addViewAll = (data)=>{
        if(hideViewAll) return data

        if(!showAggs || type == 'storage_folders') return data;

        const typeName =  {
            'collections': t('collections','Collections'),
            'lightboxes': t('lightboxes','Lightboxes'),
            'storage_folders': t('storage-folders','Storage Folders')
        }[type];

        const viewAllNode = {
            key: '_all',
            viewAllType: type,
            selectable: true,
            hasChildren: false,
            isLeaf: true,
            checkable: false,
            title: () => {
                const total = allAssetCounts && allAssetCounts[typeClass];

                return (
                    <>
                        <EyeOutlined/> <em>{t('view-all-in-container','View All in {{type}}', {type:typeName})}</em>
                        <div style={{float:'right'}}>
                            <Tag
                                style={{margin:0, cursor:'pointer'}}
                                color={'default'}
                            >
                                <em>{n(total)}</em>
                            </Tag>
                        </div>
                    </>

                )
            },
        };

        const nodes = [viewAllNode, ...data]

        if(type == 'collections' && (allAssetCounts && allAssetCounts['NoCollection'] != false)) {
            const viewNoneNode = {
                key: '_none',
                viewAllType: 'no-collection',
                selectable: true,
                checkable: false,
                hasChildren: false,
                isLeaf: true,
                title: () => {
                    const total = allAssetCounts && allAssetCounts['NoCollection'];

                    return (
                        <>
                            <EyeInvisibleOutlined/> <em>{t('view-all-not-in-collection','View All Not in Collection')}</em>
                            <div style={{float:'right'}}>
                                <Tag
                                    style={{margin:0, cursor:'pointer'}}
                                    color={'default'}
                                >
                                    <em>{n(total)}</em>
                                </Tag>
                            </div>
                        </>

                    )
                },
            };

            nodes.splice(1,0, viewNoneNode)
        }

        return nodes
    }

    const treeRef = useRef()

    const domId = `tree-${type}`;
    const [openInfoId, setOpenInfoId] = useState()

    const resetFocus = key => {
        setOpenInfoId(null)
        setTimeout(()=>{
            document.querySelector(`#${domId} input`).focus()
            const state = treeRef.current
            state.activeKey = key
            treeRef.current.setState({...state})
        }, 500)
    }

    const onKeyDown = e => {
        const id = treeRef.current.getActiveItem()?.id;
        if(e.key === 'i' && id) {
            setOpenInfoId(parseInt(id))
        }
    }

    const [bulkMoveAssetGroupModalOpen, setBulkMoveAssetGroupModalOpen] = useState()

    return (
        <TreeContext.Provider
            value={{
                treeData, setTreeData, type, sandbox, loadTreeData, expand,
                enableAssetDrop, showAggs, organizerSelection, selectableIf, checkableIf, hideCountIf, hideInfo,
                showBadge, dimIf, loadNew, checkedKeys, checkedIds, showAggsCount, openInfoId, resetFocus
            }}
        >
            <Row align={'top'} wrap={false}>
                <Col flex={'auto'}>
                    <AntInput
                      style={{ marginBottom: 8 }}
                      placeholder={t('search','Search...')}
                      allowClear
                      onChange={search}
                      prefix={<SearchOutlined style={{opacity:0.5}}/>}
                    />
                </Col>

                <Col flex={'none'} style={{textAlign:'right'}}>
                    <Can I={'create'} a={typeClass}>
                        {!readOnly && !hideRearrangeButton && (
                            <>
                                {editMode && (
                                    <>
                                        <Dropdown
                                            arrow
                                            trigger={['hover', 'click']}
                                            disabled={!checkedKeys.length}
                                            menu={{
                                                items: [ {
                                                    key: 'move',
                                                    icon: <EditOutlined/>,
                                                    label: <>{t('move', 'Move')}...</>,
                                                    onClick: ()=> setBulkMoveAssetGroupModalOpen(true)
                                                }]
                                            }}
                                        >
                                            <Button
                                                type={'text'}
                                                size={'small'}
                                                onClick={(e)=> e.stopPropagation()}
                                                icon={<SettingOutlined/>}
                                                id={`asset-group-actions-menu-${type}`}
                                            />
                                        </Dropdown>

                                        <BulkMoveAssetGroupModal
                                            afterMove={()=> setCheckedKeys([])}
                                            open={bulkMoveAssetGroupModalOpen}
                                            setOpen={setBulkMoveAssetGroupModalOpen}
                                        />
                                    </>
                                )}
                                <Tooltip title={editMode ? t('tooltip.leave-rearrange-mode','Leave Rearrange Mode') : t('tooltip.enter-rearrange-mode','Enter Rearrange Mode')}>
                                    {editMode ? (
                                        <Button type={'text'} size='small' onClick={() => setMode('')}>
                                            <EditFilled style={{cursor: 'pointer'}} />
                                        </Button>
                                    ) : (
                                        <Button type={'text'} size='small' onClick={() => setMode('Edit')} id={`rearrange-${type}-button`}>
                                            <EditOutlined style={{cursor: 'pointer'}}/>
                                        </Button>
                                    )}
                                </Tooltip>
                            </>
                        )}
                        {!hideAddButton && (
                            <Can I={'manage'} a={typeClass}>
                                <AddAssetGroupDropdown type={type}/>
                            </Can>
                        )}

                    </Can>
                </Col>
            </Row>

            <div aria-label={t(`${type}-tree-label`,`${typeClass} Tree`)} id={domId}>
                {!loading && treeData.length ? (
                    <Tree
                        ref={treeRef}
                        draggable={!readOnly && !dragging && (editMode || draggable)}
                        selectable
                        blockNode
                        selectedKeys={selectedKeys}
                        checkable={multiple || editMode}
                        checkStrictly
                        treeData={searching ? searchResults : addViewAll(treeData)}
                        loadData={loadTreeData}
                        onCheck={onCheck}
                        onSelect={onSelect}
                        onDrop={onDrop}
                        autoExpandParent={false}
                        expandedKeys={expandedKeys}
                        loadedKeys={loadedKeys}
                        onExpand={expand}
                        aria-label={t(`${type}-tree-label`,`${typeClass} Tree`)}
                        onKeyDown={onKeyDown}
                    />
                    ) : (loading ? <Skeleton loading active/> : <Empty description={t('alert.no-container-yet', 'No {{type}} yet.', {type})}/>)
                }
            </div>

        </TreeContext.Provider>
    )
}

const AddAssetGroupDropdown = ({type})=>{
    const {t} = useTranslation();
    const addAssetGroup = useAddAssetGroup({menu: true})
    const addOrganizer = useAddAssetGroup({menu: true, organizer: true})

    const addProject = type === 'lightboxes' && useAddAssetGroup({menu: true, project: true})
    const addLightroom = type === 'storage_folders' && useAddAssetGroup({menu: true, organizer: true, lightroom: true})

    return (
        <>
            <Dropdown
                overlayStyle={{width:250}}
                placement={'bottomLeft'}
                trigger={['hover','click']}
                arrow
                menu={{
                    items: _.compact([
                        {
                            key: 'add',
                            ...addAssetGroup,
                        },
                        type === 'lightboxes' ? {
                                key: 'add-project',
                                ...addProject
                            } : null,
                        {
                            key: 'add-organizer',
                            ...addOrganizer
                        },
                        type === 'storage_folders' ?
                            {
                                key: 'add-lightroom',
                                ...addLightroom
                            }
                            : null
                    ])
                }}
            >
                <Button type={'text'} size={'small'} onClick={(e)=> e.stopPropagation()} aria-label={t('add-asset-group-menu','Add Asset Group Menu')}>
                    <PlusOutlined/>
                </Button>
            </Dropdown>

            {addAssetGroup.modal}
            {addOrganizer.modal}

            {addProject?.modal}
            {addLightroom?.modal}
        </>
    )
}

const useAddAssetGroup = ({organizer, sandbox, parent, menu, contextMenu, project=false, lightroom})=> {
    const {treeData, setTreeData, type, loadTreeData, expand, organizerSelection, selectableIf, dimIf, checkableIf} = useContext(TreeContext);

    let typeName;
    if(project) typeName = 'Project'
    else if(parent?.sub_type == 'folder') typeName = 'Bin'
    else {
        typeName = {
            'collections': 'Collection',
            'lightboxes': 'Lightbox',
            'storage_folders': 'Storage Folder'
        }[type];
    }

    const typeIcon = project ? <ProjectIcon/> : {
        'collections': <CollectionIcon/>,
        'lightboxes': <LightboxIcon/>,
        'storage_folders': <StorageFolderIcon/>
    }[type];

    const isBin = !parent?.organizer && parent?.sub_type != 'project' && parent?.type == 'Lightbox'

    const [popoverVisible, setPopoverVisible] = useState(false);

    const autoFocusInput = useRef(null);

    let location = useLocation()
    const navigate = useNavigate();

    const [loading, setLoading] = useState()

    const clickNew = e => {
        (e.domEvent || e).preventDefault();
        (e.domEvent || e).stopPropagation();

        if(!organizer && type === 'lightboxes' && !isBin) {
            // Open Drawer
            let hash = `/lightboxes/new?`
            if(project) hash += `project=true`
            if(parent) hash += `&parent_id=${parent?.id}`
            location.hash = hash
            navigate(location)
            return false;

        } else {
            setPopoverVisible(true)
            setTimeout(()=>{
                autoFocusInput.current.focus()
            }, 100)
        }
    }

    const {t} = useTranslation();

    const orgType = {
        'collections': t('library','Library'),
        'lightboxes': t('lightbox','Lightbox'),
        'storage_folders': t('storage','Storage')
    }[type]

    const title = organizer ? (
        <>
            <FolderOutlined/> {lightroom ? (<>{t('new-lightroom-publish-connection','New Lightroom Publish Connection')}</>) : (<>{t('new','New')} {orgType} {t('organizer','Organizer')}</>)}
        </>
    ) : <>{typeIcon} {t('new','New')} {isBin ? t('bin','Bin') : typeName}</>;

    const buttonInner = (
        <>
            {organizer ? <FolderAddFilled/> : <FolderAddOutlined/>} {organizer ? t('add-organizer','Add Organizer') : t('add-container', 'Add {{type}}', {type: isBin ? 'Bin' : typeName} )}...
        </>
    )

    const modal = (
        <Formik
            initialValues={{}}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                values = {...values, sandbox, organizer, project, parent_id: parent?.id, publish_type: lightroom && 'lightroom'};

                let data = {parent_sub_type: parent?.sub_type};
                data[pluralize.singular(type)] = values;

                setLoading(true)
                api.post(`/api/${type}`, data).then(res => {
                    actions.resetForm();
                    actions.setSubmitting(false);

                    const node = getNodeFromDatum(res.data, organizerSelection, selectableIf, checkableIf);

                    if(parent) {
                        loadTreeData({key: parent.slug, subType: parent.sub_type, force: true, id: parent.id}, ()=> {
                            expand(null, {expanded: true, node: parent});
                        });
                    } else {
                        treeData.unshift(node);
                    }

                    setTreeData([...treeData]);

                    setPopoverVisible(false);
                    message.success(t('container-created','{{type}} created.', {type: typeName}))
                    setLoading(false)
                })
            }}
            validationSchema={
                Yup.object({
                    name: Yup.string().required(''), // TODO: check uniqueness
                })
            }
        >
            {({values, submitForm, handleSubmit}) => {

                // This is needed since an Enter key press will submit the outer form also
                const onKeyDownCapture = (e) => {
                    if(e.keyCode == 13) {
                        e.stopPropagation();
                        e.preventDefault();
                        submitForm();
                    }
                }

                // ====================
                // Invite Member Search
                // ====================

                const [searchOptions, setSearchOptions] = useState([])

                const lastSearchValue = useRef();
                const search = _.debounce((value)=>{
                    lastSearchValue.current = value;

                    if(value == '') return setSearchOptions([]);

                    api('/api/memberships/search', {params: {q: value}}).then(res => {
                        if(value != lastSearchValue.current) return;

                        const options = res.data.map(m => {
                            return {
                                value: m.user.email,
                                label: <User user={m.user} showEmail/>
                            }
                        });

                        setSearchOptions(options);
                    });
                }, 250)

                const [searchValue, setSearchValue] = useState('');
                useEffect(()=>{
                    setSearchValue('')
                }, [popoverVisible])

                return (
                    (<Modal
                        zIndex={9001}
                        title={title}
                        open={popoverVisible}
                        destroyOnClose
                        onCancel={() => setPopoverVisible(false)}
                        centered
                        confirmLoading
                        footer={
                            <Space direction={'horizontal'}>
                                <Button type={'primary'} onClick={submitForm}>
                                    <SaveOutlined/>
                                    {t('create','Create')}
                                </Button>
                                <Button onClick={() => setPopoverVisible(false)}>{t('cancel','Cancel')}</Button>
                            </Space>
                        }
                    >
                        <Form onSubmit={handleSubmit} onKeyDownCapture={onKeyDownCapture} layout={'vertical'}>
                            <FormItem required name='name' showValidateSuccess>
                                <FloatLabel label={t('name','Name')} name={'name'} value={values?.name}
                                            description={t('name-description','e.g. Marketing')}>
                                    <Input size={'large'} required name='name' ref={autoFocusInput} autoFocus autoComplete='off'/>
                                </FloatLabel>
                            </FormItem>

                            <FormItem name='description' style={{marginTop:'.5em'}}>
                                <FloatLabel label={t('description','Description')} name={'description'} value={values?.description} description={t('optional','Optional.')}>
                                    <Input.TextArea rows={2} name='description'/>
                                </FloatLabel>
                            </FormItem>

                            {!!lightroom && (
                                <>
                                    <FormItem required name='publish_user_email' showValidateSuccess label={t('select-publish-manager-user','Select Publish Manager User')}>
                                        <AutoComplete
                                            popupMatchSelectWidth={500}
                                            defaultActiveFirstOption
                                            style={{width: '100%', margin: '.5em 0'}}
                                            onSearch={search}
                                            options={searchOptions}
                                            onSelect={setSearchValue}
                                            onChange={setSearchValue}
                                            name={'publish_user_email'}
                                            value={searchValue}
                                        >
                                            <AntInput.Search placeholder={t('search-users',"Search Users...")} autoComplete={'off'}/>
                                        </AutoComplete>
                                    </FormItem>
                                </>
                            )}
                        </Form>
                    </Modal>)
                );
            }}
        </Formik>
    )

    const label = (
        (<div>
            {menu ? (
                <>
                    {organizer ? (
                        <Space>
                            {lightroom ? (
                                <> {t('new-lightroom-publish-connection','New Lightroom Publish Connection...')} </>
                            ) : (
                                <> {t('new-container-organizer','New {{type}} Organizer...', {type:orgType})} </>
                            )}
                        </Space>
                    ) : (
                        <>{t('new','New')} {typeName}...</>
                    )}
                </>
            ) : (
                <>
                    <Tooltip title={t(`tooltip-add-sub-${parent.type}`, `Add a Sub-${parent.type} inside this ${parent.type}`)} placement={'top'}>
                        {contextMenu ? (
                            <div onClick={clickNew}>
                                {buttonInner}
                            </div>
                        ) : (
                            <Button size='small' type={contextMenu ? 'text' : 'primary'} ghost onClick={clickNew} block={organizer} loading={loading}>
                                {buttonInner}
                            </Button>
                        )}
                    </Tooltip>
                </>
            )}
        </div>)
    );

    let icon;
    if(lightroom) icon = <LightroomClassicIcon/>
    else if(organizer) icon = <OrganizerIcon/>
    else icon = typeIcon

    return {
        icon,
        label,
        onClick: clickNew,
        modal
    }
}

function AddAssetGroup(props){
    const {modal, label} = useAddAssetGroup(props)

    return (
        <>
            {label}
            {modal}
        </>
    )
}

// --- Extract
function NodeTitle({c, onUpdate}){
    // since functions called from Ant TreeNode don't see current state of parents
    const {treeData, setTreeData, type, sandbox, enableAssetDrop, showAggs, dimIf,
        showBadge, loadTreeData, showAggsCount, hideCountIf, hideInfo, openInfoId, resetFocus} = useContext(TreeContext);

    const {aggs} = useAggsState();
    const {selectedAssetAggs} = useSelectedAggsState();
    const {selectedAssetIds} = useSelectedAssetsState()

    const assetsDispatch = useAssetsDispatch()
    const selectedAssetsDispatch = useSelectedAssetsDispatch()

    const ancestry = c.ancestor_ids?.concat(c.id)?.join('/')

    const aggName = c.sub_type == 'folder' ? 'lightbox_folder_paths' : {
        'Collection': 'collection_paths',
        'StorageFolder': 'storage_folder_path',
        'Lightbox': 'lightbox_paths'
    }[c.type];

    const [count, setCount] = useState(0);
    const [total, setTotal] = useState(c.visible_assets_count || 0);
    const [totalSize, setTotalSize] = useState(c.visible_assets_size);

    useEffect(()=>{
        setTotal(c.visible_assets_count || 0)
    }, [c.visible_assets_count])

    useEffect(()=>{
        setTotalSize(c.visible_assets_size)
    }, [c.visible_assets_size])

    useEffect(()=>{
        if(!showAggs) return;

        setCount(aggs && _.find(aggs[aggName]?.buckets, agg => agg.key == ancestry)?.doc_count || 0);
    }, [showAggs, aggs]);

    const selectedAgg = selectedAssetAggs && _.find(selectedAssetAggs[aggName]?.buckets, agg => agg.key == ancestry)
    const inSelection = selectedAgg?.doc_count || 0;
    const selectedSize = selectedAgg?.file_size?.value;

    let drop, canDrop, isOver, style = {};

    const bulkJobDispatch = useBulkJobsDispatch()
    const [bulkJobId, setBulkJobId] = useState()

    const {t} = useTranslation();

    const onBulkAddFinish = (bj)=>{
        assetsDispatch({type:'reload'});
        selectedAssetsDispatch({type:'reload'});

        setTotal(total + bj.added)

        message.destroy('bulkJob')

        const verb = c.sub_type === 'folder' ? 'moved' : 'added'
        const inter = {count: bj.added, name: c.name}
        const text = bj.added == 1 ?
            t(`message-asset-${verb}`, `{{count}} asset ${verb} to {{name}}`, inter) :
            t(`message-assets-${verb}`, `{{count}} assets ${verb} to {{name}}`, inter)

        message.success(text)
    }

    if(enableAssetDrop && !c.organizer && c.sub_type != 'project' && c.publish_type != 'lightroom' && (c.type != 'Lightbox' || c.can_update_assets)) {
        [{canDrop, isOver}, drop] = useDrop({
            accept: 'asset',
            drop: ({asset}) => {
                const asset_ids = [...new Set([asset.id, ...selectedAssetIds])];

                const guid = crypto.randomUUID()

                const data = {
                    bulk_job: {
                        guid,
                        asset_ids,
                        add_asset_group_id: c.id,
                        add_asset_group_type: c.type,
                        add_asset_group_sub_type: c.sub_type
                    }
                }

                setBulkJobId(guid)
                bulkJobDispatch({type:'add', bulkJob: data.bulk_job})

                // Create BulkJob and listen:
                message.info({content: t('message-starting-bulk-job','Starting Bulk Job...'), key:'bulkJob'})

                api.post(`/api/bulk_jobs`, data).then(res => {
                }).catch((err)=>{
                    console.log(err)
                })
                return c
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
            // hover: (item) => {
            //     // console.log('hovering', props.title);
            // }
        });

        const isActive = canDrop && isOver;

        style = isActive ? {
            border: '1px solid #b7eb8f',
            backgroundColor: '#f6ffed'
        } : {};
    }

    const onArchive = ()=> {
        api.post(`/api/${type}/${c.id}/archive`, {params:{sub_type: c.sub_type}}).then(() => {
            message.success(t('message-container-archived', '{{name}} Archived', {name: c.name}))
            removeNode()
            const archiveNode = _.find(treeData, {subType:'archive'})
            if(archiveNode) loadTreeData({key: archiveNode.key, force: true})
        });
    }

    const onDestroy = ()=> {
        api.delete(`/api/${type}/${c.id}`, {params:{sub_type: c.sub_type}}).then(res => {
            if(res.data.errors) {
                return message.error(res.data.errors)
            }

            message.success(t('message-container-deleted', '{{name}} Deleted', {name: c.name}))
            removeNode()
        });
    }

    const removeNode = ()=>{
        const newRawData = [...treeData];

        // Remove Node from tree:
        const loop = (data, key, callback) => {
            data.forEach((item, index, arr) => {
                if (item.key === key) {
                    return callback(item, index, arr);
                }
                if (item.children) {
                    return loop(item.children, key, callback);
                }
            });
        };

        loop(newRawData, c.id, (item, index, arr) => {
            arr.splice(index, 1)
        });

        // TODO: updateCounts
        setTreeData(newRawData);
    }

    const [name, setName] = useState(c.name);

    const nameStyle = {textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden', minWidth:0, flex: '0 1 auto'}

    if(dimIf && dimIf(c)) nameStyle.color = 'rgb(176, 176, 176)'

    return (
        <div ref={drop} id={`asset-group-node-${c.id}`} aria-label={c.name} className={'asset-group-node'}>
            <AssetGroupInfoPopover c={c} onUpdate={onUpdate} onDestroy={onDestroy} setName={setName} type={type} onArchive={onArchive} contextMenu>
                <div style={{...style, display:'flex', justifyContent: 'flex-start', verticalAlign:'center', minWidth:0}}>
                    <div style={{flexShrink:0, marginRight:'.5em'}}>
                        {getTypeIcon(c)}
                    </div>

                    <div className='node-name' style={nameStyle}>
                        {name} {showBadge && c.children_count > 0 && <Badge count={c.children_count}/>}
                    </div>

                    {!hideInfo && (
                        <div style={{flexShrink:0, marginRight:'.5m'}}>
                            <AssetGroupInfoPopover
                                c={c}
                                onUpdate={onUpdate}
                                onDestroy={onDestroy}
                                setName={setName}
                                type={type}
                                onArchive={onArchive}
                                openInfoId={openInfoId}
                                resetFocus={resetFocus}
                            />

                            {!!c.share_links_count && (
                                <Tooltip title={`${c.share_links_count} Share ${pluralize('Link', c.share_links_count)}`}>
                                    <ShareAltOutlined style={{marginLeft:'.25em', color:'rgb(176, 176, 176)'}}/>
                                </Tooltip>
                            )}
                        </div>
                    )}

                    {(!hideCountIf || !hideCountIf(c)) && (
                        <div style={{flexShrink: 0, marginLeft:'auto'}}>
                            <Tag
                                style={{margin:0, cursor:'pointer'}}
                                color={count > 0 ? 'blue' : 'default'}
                            >
                                {showAggs ? (
                                    <Popover
                                        placement='right'
                                        content={()=> {
                                            const resultsSize = _.find(aggs[aggName]?.buckets, agg => agg.key == ancestry)?.file_size?.value

                                            const renderFileSize = (size) => {
                                                return size && <small><Typography.Text type={'secondary'} style={{marginLeft:'1em'}}>({filesize(size)})</Typography.Text></small>
                                            }

                                            return (
                                                <Descriptions bordered size='small' column={1}>
                                                    <Descriptions.Item label={t('total','Total')}>
                                                        {n(total)} {renderFileSize(totalSize)}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label={<><SearchOutlined/> {t('in-results','In Results')}</>}>
                                                        {n(count)} {renderFileSize(resultsSize)}
                                                    </Descriptions.Item>
                                                    {!!selectedAssetIds?.length && (
                                                        <Descriptions.Item label={<><CheckOutlined/> {t('in-selection','In Selection')}</>}>
                                                            {n(inSelection)} {renderFileSize(selectedSize)}
                                                        </Descriptions.Item>
                                                    )}
                                                </Descriptions>
                                            )
                                        }}>
                                        <span id={`asset-group-chooser-count-${c.id}`}>
                                            {c.reindexing_at ? (
                                                <LoadingOutlined/>
                                            ) : (
                                                <>{!!inSelection && <CheckOutlined/>} {showAggsCount ? `${n(total)} / ${n(count)}` : n(total)}</>
                                            )}
                                        </span>
                                    </Popover>
                                ) : n(total)}
                            </Tag>
                        </div>
                    )}

                    {bulkJobId && <AssetGroupBulkJobProgress bulkJobId={bulkJobId} onFinish={onBulkAddFinish}/>}
                </div>
            </AssetGroupInfoPopover>
        </div>
    )
}

// FIXME: message gets stuck when component removed from DOM
const AssetGroupBulkJobProgress = ({bulkJobId, onFinish})=> {
    const {t} = useTranslation();
    const {bulkJobs} = useBulkJobsState()
    const bulkJob = bulkJobs[bulkJobId]

    const ref = useRef()

    const [closed, setClosed] = useState(false)
    const click = ()=> {
        ref.current()
        setClosed(true)
    }

    useEffect(()=>{
        if(!bulkJob || closed) return;

        ref.current = message.open({
            key: `bulk-job-${bulkJobId}`,
            type: 'loading',
            onClick: click,
            content: (
                <>
                    {bulkJob.progress == 100 && (
                        <>
                            {t('updating-index','Updating Index...')}
                        </>
                    ) || (
                        <>
                            {t('processing','Processing')}: <Progress percent={bulkJob.progress || 0} status={'active'}/>
                        </>
                    )}
                </>
            ),
            duration: 0
        })

        if(bulkJob.done) {
            ref.current()
            setClosed(false)
            onFinish(bulkJob);
        }

        return ref.current
    }, [bulkJob?.progress, bulkJob?.done])

    return <></>
}

export {AssetGroupBulkJobProgress}

const DownloadStorageFolderButton = ({storageFolder, menu})=> {
    const {t} = useTranslation();

    return (
        <DownloadButton storageFolder={storageFolder} canDownload menu={menu} noTooltip elipsis/>
    )
}

const DuplicateToCollectionButton = ({storageFolder, menu})=> {
    const {t} = useTranslation();
    const bulkJobDispatch = useBulkJobsDispatch()
    const [bulkJobId, setBulkJobId] = useState()

    const assetGroupDispatch = useAssetGroupDispatch()

    const confirm = (e)=> {
        e.preventDefault()
        e.stopPropagation()
        setVisible(false)

        const data = {
            bulk_job: {
                duplicate_storage_folder_id: storageFolder.id,
                new_asset_group_parent_id: collection?.id,
            }
        }

        api.post(`/api/bulk_jobs`, data).then(res => {
            setBulkJobId(res.data.guid)
            bulkJobDispatch({type:'add', bulkJob: res.data})
        }).catch((err)=>{
            console.log(err)
        })
    }

    const onFinish = (bj)=>{
        message.success(t('done','Done!'))
        assetGroupDispatch({type:'reloadCollections'})
    }

    const onClick = (e)=> {
        e.preventDefault()
        e.stopPropagation()
    }

    const [visible, setVisible] = useState()
    const [collection, setCollection] = useState()

    return (
        (<div {...onClick}>
            <Modal
                open={visible}
                onCancel={()=> setVisible()}
                zIndex={9999}
                title={
                    <>
                        <BranchesOutlined/> {t('button-duplicate-to-collection','Duplicate to Collection')}
                    </>
                }
                onOk={confirm}
            >
                <VerticalSpace>
                    <em>{t('select-a-destination-collection', 'Select a destination Collection (leave blank for root).')}</em>
                    <AssetGroupChooser
                        setSelectedGroup={setCollection}
                        organizerSelection
                        type={'collections'}
                    />
                </VerticalSpace>
            </Modal>
            <Tooltip title={t('tooltip-duplicate-to-collection','Duplicate this Folder and any Sub-Folders to a Collection')} placement={'top'}>
                {menu ? (
                    <a style={{width:'100%', color: 'inherit'}} onClick={()=> setVisible(true)}> <BranchesOutlined/> {t('button-duplicate-to-collection-ellipses','Duplicate to Collection...')} </a>
                ) : (
                    <Button size='small' icon={<BranchesOutlined/>} onClick={()=> setVisible(true)}>{t('button-duplicate-to-collection-ellipses','Duplicate to Collection...')}</Button>
                )}
            </Tooltip>
            {bulkJobId && <AssetGroupBulkJobProgress bulkJobId={bulkJobId} onFinish={onFinish}/>}
        </div>)
    );
}

const DuplicateToLightboxButton = ({collection, menu})=> {
    const {t} = useTranslation();
    const bulkJobDispatch = useBulkJobsDispatch()
    const [bulkJobId, setBulkJobId] = useState()

    const assetGroupDispatch = useAssetGroupDispatch()

    const confirm = (e)=> {
        e.preventDefault()
        e.stopPropagation()

        const data = {
            bulk_job: {
                duplicate_collection_id: collection.id
            }
        }

        api.post(`/api/bulk_jobs`, data).then(res => {
            setBulkJobId(res.data.guid)
            bulkJobDispatch({type:'add', bulkJob: res.data})
        }).catch((err)=>{
            console.log(err)
        })
    }

    const onFinish = (bj)=>{
        message.success(t('done','Done!'))
        assetGroupDispatch({type:'reloadLightboxes'})
    }

    const onClick = (e)=> {
        e.preventDefault()
        e.stopPropagation()
    }

    return (
        <div onClick={onClick}>
            <Popconfirm title={t('confirm-duplicate-to-lightbox','Duplicate to Lightbox?')} onConfirm={confirm} onClick={onClick} zIndex={9999}>
                <Tooltip title={t('tooltip-duplicate-to-lightbox','Duplicate this Collection and any Sub-Collections to a Lightbox')} placement={'top'}>
                    {menu ? (
                        <div style={{width:'100%'}}><BranchesOutlined/> {t('button-duplicate-to-lightbox-ellipses','Duplicate to Lightbox...')}</div>
                    ) : (
                        <Button size='small' icon={<BranchesOutlined/>}>{t('button-duplicate-to-lightbox','Duplicate to Lightbox')}</Button>
                    )}
                </Tooltip>
                {bulkJobId && <AssetGroupBulkJobProgress bulkJobId={bulkJobId} onFinish={onFinish}/>}
            </Popconfirm>
        </div>
    )
}

const CloneButton = ({assetGroup, menu})=> {
    const {t} = useTranslation();
    const bulkJobDispatch = useBulkJobsDispatch()
    const [bulkJobId, setBulkJobId] = useState()

    const assetGroupDispatch = useAssetGroupDispatch()

    const [cloning, setCloning] = useState(false)
    const confirm = (e)=> {
        e.preventDefault()
        e.stopPropagation()

        setCloning(true)

        const data = {
            bulk_job: {
                clone_asset_group_id: assetGroup.asset_group_id,
                clone_asset_group_type: assetGroup.type
            }
        }

        api.post(`/api/bulk_jobs`, data).then(res => {
            setBulkJobId(res.data.guid)
            bulkJobDispatch({type:'add', bulkJob: res.data})
        }).catch((err)=>{
            console.log(err)
        })
    }

    const onFinish = (bj)=>{
        setCloning(false)
        message.success(t('message-clone-done','Done! Please give the assets a moment to populate.'))

        setTimeout(()=> {
            let type;
            switch(assetGroup.type) {
                case 'Lightbox':
                    type = 'Lightboxes'
                    break;
                case 'Collection':
                    type = 'Collections'
                    break;
            }

            assetGroupDispatch({type:`reload${type}`})
        }, 1000)

    }

    const onClick = (e)=> {
        e.preventDefault()
        e.stopPropagation()
    }

    return (
        <div onClick={onClick}>
            <Popconfirm title={t(`confirm-clone-${assetGroup.type}`,`Clone ${assetGroup.type}?`)} onConfirm={confirm} onCLick={onClick} zIndex={9999}>
                <Tooltip title={t(`tooltip-clone-${assetGroup.type}`,`Make a duplicate of this ${assetGroup.type} and any Sub-${assetGroup.type}s`)} placement={'right'}>
                    {menu ? (
                        <div style={{width:'100%'}}><CloneIcon/> {t('button-clone-ellipses','Clone...')}</div>
                    ) : (
                        <Button size='small' icon={<CloneIcon/>} loading={cloning}>{t('button-clone','Clone...')}</Button>
                    )}
                </Tooltip>
                {bulkJobId && <AssetGroupBulkJobProgress bulkJobId={bulkJobId} onFinish={onFinish}/>}
            </Popconfirm>
        </div>
    )
}

const getTypeIcon = (c)=> {
    let typeIcon
    if(c.sandbox) typeIcon = c.organizer ? <LockFilled/> : <LockOutlined />
    else if(c.publish_type == 'lightroom' && c.organizer) typeIcon = <LightroomClassicIcon/>;
    else if(c.has_lightroom_connection) typeIcon = <LightroomIcon/>;
    else if(c.sub_type === 'archive') typeIcon = <OrganizerIcon/>;
    else if(c.organizer) typeIcon = <OrganizerIcon/>;
    else {
        typeIcon = {
            'project': <ProjectIcon/>,
            'folder': <BinIcon/>
        }[c.sub_type] || {
            'Collection': <CollectionIcon/>,
            'Lightbox': <LightboxIcon/>,
            'StorageFolder': <StorageFolderIcon/>
        }[c.type];
    }
    return  typeIcon
}

const AssetGroupInfoPopover = ({c, onUpdate, setName: updateName, sandbox, onDestroy, viewOnly, children, onArchive, contextMenu, openInfoId, resetFocus})=> {
    // console.log('AssetGroupInfoPopover', c)
    const {t} = useTranslation();
    const ability = useContext(AbilityContext);

    const [name, setName] = useState(c.name);
    const [description, setDescription] = useState(c.description);

    const currentOrg = useCurrentOrg()
    const {currentAssetGroup} = useAssetGroupState()

    const currentUser = useCurrentUser()

    const typeName = {'project': t('project','Project'), 'folder': t('bin','Bin')}[c.sub_type]
        || {'StorageFolder' : t('storage-folder','Storage Folder')}[c.type]
        || t(c.type, c.type);

    const orgType = {
        'Collection': t('library','Library'),
        'Lightbox': t('lightbox','Lightbox'),
        'StorageFolder': t('storage','Storage')
    }[c.type]

    let title;
    if(c.publish_type === 'lightroom') {
        title = t('lightroom-publish-collection-info', `Lightroom Publish Connection Info`);
    } else if(c.organizer) {
        title = t('container-organizer-info', '{{type}} Organizer Info', {type: orgType});
    } else {
        title = t('container-info', '{{type}} Info', {type: typeName});
    }

    const [visible, setVisible] = useState();
    const onOpenChange = (value)=>{
        setVisible(value)
    }

    useEffect(() => {
        if(openInfoId === c.id && !visible) setVisible(true)
    }, [openInfoId]);

    const [collection, setCollection] = useState(c);
    const isLightbox = collection.type === 'Lightbox'
    const isStorageFolder = collection.type === 'StorageFolder'
    const isCollection = collection.type === 'Collection'

    useEffect(()=>{
        setName(c.name)
        setDescription(c.description)
        setCollection(c)
    }, [c.id])

    const route = {
        'Collection' : 'collections',
        'StorageFolder' : 'storage_folders',
        'Lightbox' : 'lightboxes',
    }[c.type]

    let location = useLocation()
    const navigate = useNavigate();

    const clickManage = (e,c)=>{
        e.preventDefault()
        e.stopPropagation();
        const hash = `/${route}/${c.id}/edit`
        location.hash = location.hash === `#${hash}` ? '' : hash
        navigate(location)
        return false;
    }

    const [loading, setLoading] = useState()
    useEffect(()=>{
        if(!visible) return;

        setLoading(true)
        api(`/api/${route}/${c.id}?sub_type=${c.sub_type}`).then(res => {
            setCollection(res.data)
            setLoading(false)
        }).catch(e => {
            setLoading(false)
            console.log('get asset group error', e)
        })
    }, [visible])

    const getOrgPath = useOrgPath()

    const assetGroupDispatch = useAssetGroupDispatch();

    const update = (data, cb)=> {
        const field = {
            'Collection': 'collection',
            'StorageFolder': 'storage_folder',
            'Lightbox': 'lightbox'
        }[c.type];

        let params = {sub_type: c.sub_type, parent_sub_type: c.parent_sub_type};
        params[field] = data;

        api.put(`/api/${route}/${c.id}`, params).then(res => {
            if(res.data.error) {
                return message.error(JSON.stringify(res.data.error))
            }

            if(currentAssetGroup?.id === c.id && currentAssetGroup.slug !== res.data.slug) {
                assetGroupDispatch({type:'updateCurrentAssetGroup', assetGroup: res.data})

                const path = {
                    'Collection': 'collections',
                    'StorageFolder': 'folders',
                    'Lightbox': 'projects'
                }[c.type];

                navigate(getOrgPath(`/explore/${path}/${res.data.slug}`))
            }

            cb && cb(res)
        })
    }

    const [disconnecting, setDisconnecting] = useState()

    const disconnectBox = ()=>{
        setDisconnecting(true)
        update({box_folder_id: null}, res =>{
            setDisconnecting(false)
            message.success(t('message-watch-folder-disconnected','Watch folder disconnected!'))
            setCollection({...collection, ...res.data})
        })
    }

    const [connectingFrame, setConnectingFrame] = useState()
    const onFramePick = ({team, project, frame_include_videos}) => {
        setConnectingFrame(true)
        message.loading(t('message-connecting-frame-folder','Connecting Frame.io Folder...'))

        update({frame_project_id: project.id, frame_project_name: project.name, frame_team_name: team.name, frame_team_id: team.id, frame_include_videos}, res =>{
            setConnectingFrame(false)
            message.success(t('message-frame-watch-folder-connected','Watch folder connected to Frame.io!'))
            setCollection({...collection, ...res.data})
        })
    }

    const disconnectFrame = ()=>{
        setDisconnecting(true)
        update({frame_project_id: null}, res =>{
            setDisconnecting(false)
            message.success(t('message-watch-folder-disconnected','Watch folder disconnected!'))
            setCollection({...collection, ...res.data})
        })
    }

    const [connectingBox, setConnectingBox ] = useState()
    const onBoxFolderPick = folders => {
        setConnectingBox(true)
        message.loading(t('message-connecting-box-folder','Connecting Box.com Folder...'))

        update({box_folder_id: folders[0].id}, res =>{
            setConnectingBox(false)
            message.success(t('message-box-watch-folder-connected','Watch folder connected Box.com!'))
            setCollection({...collection, ...res.data})
        })
    }

    const disconnectDropbox = ()=>{
        setDisconnecting(true)
        update({dropbox_folder_id: null}, res =>{
            setDisconnecting(false)
            message.success(t('message-watch-folder-disconnected','Watch folder disconnected!'))
            setCollection({...collection, ...res.data})
        })
    }

    const [connectingDropbox, setConnectingDropbox] = useState()
    const onDropboxFolderPick = folders => {
        setConnectingDropbox(true)
        message.loading(t('message-connecting-dropbox-folder','Connecting Dropbox Folder...'))

        update({dropbox_folder_id: folders[0].id}, res =>{
            setConnectingDropbox(false)
            message.success(t('message-dropbox-watch-folder-connected','Watch folder connected to Dropbox!'))
            setCollection({...collection, ...res.data})
        })
    }

    const disconnectGoogleDrive = ()=>{
        setDisconnecting(true)
        update({google_drive_folder_id: null}, res =>{
            setDisconnecting(false)
            message.success('Watch folder disconnected!')
            setCollection({...collection, ...res.data})
        })
    }

    const [connectingGoogleDrive, setConnectingGoogleDrive] = useState()
    const onGoogleDrivePick = folders => {
        message.loading('Connecting Google Drive Folder...')

        setConnectingGoogleDrive(true)
        update({google_drive_folder_id: folders[0].id}, res =>{
            setConnectingGoogleDrive(false)
            message.success('Watch folder connected to Google Drive!')
            setCollection({...collection, ...res.data})
        })
    }

    const hasActiveWorkflow = collection.workflow_steps?.filter(s => !s.workflow?.auto )?.length

    const onChange = (str) => {
        setName(str);
        updateName && updateName(str)
        update({name: str});
    }

    const onChangeDescription = (str) => {
        setDescription(str);
        update({description: str});
    }

    const timezone = useTimezone()

    let integration;
    if(collection.box_folder_id) integration = 'box';
    else if(collection.dropbox_folder_id) integration = 'dropbox';
    else if(collection.frame_project_id) integration = 'frame';
    else if(collection.google_drive_folder_id) integration = 'google_drive';

    let canSetWatch =
        c.editable && !c.organizer &&
        (isStorageFolder && (currentOrg?.box_token || currentOrg?.dropbox_token || currentOrg?.frame_account || currentOrg?.google_drive_access_token)) ||
        (isLightbox && currentOrg?.frame_account);

    const showManage =
        c.editable && c.type !== 'StorageFolder' && c.sub_type !== 'folder' && !(isLightbox && c.organizer);

    const showAddButton = c.editable && c.publish_type !== 'lightroom';
    const showArchiveButton = c.editable && isLightbox && c.sub_type !== 'folder' && c.sub_type !== 'archive' && !c.archived;
    const showDeleteButton = !loading && c.editable && c.sub_type !== 'archive' && c.auto_group !== 'sandboxes' && !c.block_destroy;

    const deleteButtonDisabled = hasActiveWorkflow;

    const ArchiveButton = (
        <Popconfirm
            title={
                <>
                    {t('confirm-move-to-archive','Move to Archive?')}
                </>
            }
            zIndex={1034}
            onConfirm={onArchive}
        >
            <Tooltip
                placement={'bottom'}
                title={t('tooltip.archive-container-button', 'The Archive button will move this {{type}} to the Archive organizer, but won’t delete any content nor remove any members', {type: c.sub_type === 'project' ? 'Project' : 'Lightbox'})}
            >
                {contextMenu ? (
                    <div style={{width:'100%'}}>
                        <Typography.Text type={'danger'}>
                            <ArchiveIcon/> {t('button-archive-ellipses','Archive...')}
                        </Typography.Text>
                    </div>
                ) : (
                    <Button danger size={'small'}>
                        <ArchiveIcon/> {t('button-archive-ellipses','Archive...')}
                    </Button>
                )}
            </Tooltip>
        </Popconfirm>
    )

    const DeleteButton = (
        <div onClick={e => e.stopPropagation()}>
            <Popconfirm
                title={
                    <>
                        {t('confirm-delete','Delete?')}
                        {collection.type == 'StorageFolder' && (
                            <Alert
                                style={{marginTop:'1em'}}
                                type={'warning'}
                                message={
                                    <>
                                        {t('folder-delete-warning','Deleting this Folder will delete all sub-folders and move all assets to the Trash.')}
                                    </>
                                }
                            />
                        )}

                        {collection.users?.length > 1 && (
                            <Alert
                                style={{marginTop:'1em'}}
                                type={'warning'}
                                message={
                                    <>
                                        {t('lightbox-delete-warning','Deleting this Lightbox will also remove {{count}} other User\'s access.', {count: collection.users.length - 1})}
                                    </>
                                }
                            />
                        )}

                        {collection.has_lightroom_connection && (
                            <Alert
                                style={{marginTop:'1em', maxWidth:400}}
                                type={'warning'}
                                message={
                                    <>
                                        {t('lightbox-with-lightroom-connection-delete-warning','Deleting this Lightbox will automatically delete the Album on Lightroom (but not the files). This may make the files hard to find. You may want to add these files to a regular Lightroom Album before you delete this Lightbox. If you don’t want these files in Lightroom any longer, you may wish to delete the files from Lightroom before deleting this Lightbox.')}
                                    </>
                                }
                            />
                        )}
                    </>
                }
                zIndex={9999}
                onConfirm={onDestroy}
            >
                <Tooltip title={!!hasActiveWorkflow && t('tooltip.active-workflow-warning','There are active associated Workflows, please delete those before deleting this group.')}>
                    {contextMenu ? (
                        <div style={{width:'100%'}}>
                            <Typography.Text type={'danger'}>
                                <DeleteOutlined/> {t('button-delete-ellipses','Delete...')}
                            </Typography.Text>
                        </div>
                    ) : (
                        <Button danger size={'small'} disabled={deleteButtonDisabled}>
                            <DeleteOutlined/> {t('button-delete-ellipses','Delete...')}
                        </Button>
                    )}
                </Tooltip>
            </Popconfirm>
        </div>
    )

    const TypeIcon = c.sub_type === 'project' ? <ProjectIcon/> : {
        'Collection': <CollectionIcon/>,
        'Lightbox': <LightboxIcon/>,
        'StorageFolder': <StorageFolderIcon/>
    }[c.type];

    const modal = (
        <Modal
            title={
                <Space>
                    {showManage && (
                        <Button ghost type='primary' size={'small'} icon={<SettingOutlined/>} onClick={(e)=> clickManage(e,c)} id={`manage-asset-group-btn`}>
                            {t('manage','Manage')}
                        </Button>
                    )}

                    {TypeIcon}
                    <strong>{title}</strong>
                </Space>
            }
            afterOpenChange={onOpenChange}
            open={visible}
            width={650}
            zIndex={1032}
            onCancel={e=> {
                e.stopPropagation()
                setVisible(false);
                resetFocus && resetFocus(c.slug)
            }}
            footer={null}
            onClick={e => e.stopPropagation()}
        >
            <VerticalSpace size={16}>
                <Descriptions bordered size='small' column={1} style={{width:'100%'}}>
                    <Descriptions.Item label={t('name','Name')}>
                        <Typography.Text
                            style={{display:'inline'}}
                            editable={c.editable && {onChange}}
                        >
                            {name}
                        </Typography.Text>
                    </Descriptions.Item>

                    {(c.editable || c.description?.length) && (
                        <Descriptions.Item label={t('description','Description')}>
                            <Typography.Paragraph
                                editable={c.editable && {onChange: onChangeDescription}}
                            >
                                {description || ''}
                            </Typography.Paragraph>
                        </Descriptions.Item>
                    )}

                    {c.visible_assets_count && (
                        <Descriptions.Item label={t('assets','Assets')}>{c.visible_assets_count}</Descriptions.Item>
                    )}
                    {c.editable && (
                        <Descriptions.Item label={t('child-sort-order', 'Child Sort Order')}>
                            <ChildrenSortOrder c={c}/>
                        </Descriptions.Item>
                    )}

                    {collection.user_group && (
                        <Descriptions.Item label={t('user-group','User Group')}> <UserGroupIcon/> {collection.user_group.name} </Descriptions.Item>
                    )}

                    {collection.embargoed && (
                        <Descriptions.Item label={t('embargoed','Embargoed')}>
                            {collection.embargoed ? <Tooltip title={t('embargo-on','Embargo On')}><EyeInvisibleOutlined/></Tooltip> : <Tooltip title={t('embargo-ended','Embargo Ended')}><EyeOutlined/></Tooltip>}
                            &nbsp;
                            {collection.embargo_ends_at ? moment.tz(collection.embargo_ends_at, timezone).format('MM/DD/YYYY HH:mm:SS z') : t('never-expires','Never Expires')}
                        </Descriptions.Item>
                    )}

                    {(c.editable || collection.links?.length) && (
                        <Descriptions.Item label={t('links','Links')}>
                            <Links what={collection} type={'AssetGroup'} id={collection.asset_group_id}/>
                        </Descriptions.Item>
                    )}

                    <Descriptions.Item label={t('created','Created')}> <TimeAgo date={c.created_at}/> {t('by','by')} <User user={c.user}/></Descriptions.Item>

                    {canSetWatch && (
                        <Descriptions.Item label={<><ApiOutlined/> {t('watch-folder','Watch Folder')} <HelpPopover code={'connect-watch-folder'}/></>}>
                            <Space direction={'vertical'} style={{width:'100%'}}>
                                {collection.type === 'StorageFolder' && (
                                    <>
                                        {collection.dropbox_folder_id ? (
                                            <div>
                                                <a href={`https://www.dropbox.com/home${collection.dropbox_folder_name}`} target={'_blank'}><DropboxOutlined/> Dropbox: {collection.dropbox_folder_name}</a>
                                                <Popconfirm
                                                    title={
                                                        <>
                                                            {t('confirm-disconnect-watch-folder','Disconnect Watch Folder?')}
                                                        </>
                                                    }
                                                    zIndex={1034}
                                                    onConfirm={disconnectDropbox}
                                                    onClick={e => e.stopPropagation()}
                                                >
                                                    <Button danger size={'small'} style={{marginLeft:'1em'}} loading={disconnecting}>
                                                        <CloseOutlined/>
                                                    </Button>
                                                </Popconfirm>
                                            </div>
                                        ) : !integration && currentOrg?.dropbox_token && (
                                            <DropboxFolderPicker onPick={onDropboxFolderPick} modalTitle={t('choose-watch-folder-on-dropbox','Choose Watch Folder on Dropbox')} chooseRootLabel={t('choose-root-folder','Choose Root Folder')}>
                                                <Button size={'small'} icon={<DropboxOutlined/>} loading={connectingDropbox}>{t('button-choose-on-dropbox','Choose on Dropbox')}</Button>
                                            </DropboxFolderPicker>
                                        )}

                                        {collection.box_folder_id ? (
                                            <div>
                                                <a href={`https://app.box.com/folder/${collection.box_folder_id}`} target={'_blank'}>Box.com: {collection.box_folder_name}</a>
                                                <Popconfirm
                                                    title={
                                                        <>
                                                            {t('confirm-disconnect-watch-folder','Disconnect Watch Folder?')}
                                                        </>
                                                    }
                                                    zIndex={1034}
                                                    onConfirm={disconnectBox}
                                                    onClick={e => e.stopPropagation()}
                                                >
                                                    <Button danger size={'small'} style={{marginLeft:'1em'}} loading={disconnecting}>
                                                        <CloseOutlined/>
                                                    </Button>
                                                </Popconfirm>
                                            </div>
                                        ) : !integration && currentOrg?.box_token && (
                                            <BoxFolderPicker onPick={onBoxFolderPick} modalTitle={t('select-box-watch-folder','Select Folder to Auto-Import from Box.com')} id={'choose-watch-folder'}>
                                                <Button size={'small'} icon={<CloudOutlined/>} loading={connectingBox}>{t('button-choose-on-box','Choose on Box.com')}</Button>
                                            </BoxFolderPicker>
                                        )}

                                        {/*{collection.google_drive_folder_id ? (*/}
                                        {/*    <div>*/}
                                        {/*        <GoogleDriveIcon/> Google Drive: {collection.google_drive_folder_name}*/}
                                        {/*        <Popconfirm*/}
                                        {/*            title={*/}
                                        {/*                <>*/}
                                        {/*                    {t('disconnect-watch-folder','Disconnect Watch Folder?')}*/}
                                        {/*                </>*/}
                                        {/*            }*/}
                                        {/*            zIndex={1034}*/}
                                        {/*            onConfirm={disconnectGoogleDrive}*/}
                                        {/*            onClick={e => e.stopPropagation()}*/}
                                        {/*        >*/}
                                        {/*            <Button danger size={'small'} style={{marginLeft:'1em'}} loading={disconnecting}>*/}
                                        {/*                <CloseOutlined/>*/}
                                        {/*            </Button>*/}
                                        {/*        </Popconfirm>*/}
                                        {/*    </div>*/}
                                        {/*) : !integration && currentOrg?.google_drive_access_token && (*/}
                                        {/*    <GoogleDriveFolderPicker onPick={onGoogleDrivePick} modalTitle={<><GoogleDriveIcon/> {t('select-folder-to-auto-import-from-google-drive','Select Folder to Auto-Import from Google Drive')}</>} chooseRootLabel={'Choose Root Folder'}>*/}
                                        {/*        <Button size={'small'} icon={<GoogleDriveIcon style={{marginRight:4}}/>} loading={connectingGoogleDrive}>{t('choose-on-google-drive','Choose on Google Drive')}</Button>*/}
                                        {/*    </GoogleDriveFolderPicker>*/}
                                        {/*)}*/}
                                    </>
                                )}

                                {(isLightbox || isStorageFolder) && (
                                    <>
                                        {collection.frame_project_id ? (
                                            <div>
                                                <a href={`https://app.frame.io/projects/${collection.frame_project_id}`} target={'_blank'}>Frame.io: {collection.frame_project_name}</a>
                                                <Popconfirm
                                                    title={
                                                        <>
                                                            {t('confirm-disconnect-watch-folder','Disconnect Watch Folder?')}
                                                        </>
                                                    }
                                                    zIndex={1034}
                                                    onConfirm={disconnectFrame}
                                                    onClick={e => e.stopPropagation()}
                                                >
                                                    <Button danger size={'small'} style={{marginLeft:'1em'}} loading={disconnecting}>
                                                        <CloseOutlined/>
                                                    </Button>
                                                </Popconfirm>
                                            </div>
                                        ) : !integration && currentOrg?.frame_account && (
                                            <FrameProjectPickerButton onPick={onFramePick} connecting={connectingFrame}/>
                                        )}
                                    </>
                                )}
                            </Space>
                        </Descriptions.Item>
                    )}

                    {(c.publish_type === 'lightroom') && (
                        <Descriptions.Item label={t('publish-manager','Publish Manager')}>
                            <User user={c.publish_user}/>
                            {c.publish_user?.id == currentUser?.id && (
                                <LightroomConnectionButton storageFolder={c}/>
                            )}
                        </Descriptions.Item>
                    )}
                </Descriptions>

                {(c.publish_type === 'lightroom') && c.publish_user && (
                    <Collapse size={'small'}>
                        <Collapse.Panel header={<><ApiOutlined/> {t('lightroom-connection-info','Lightroom Connection Info')}</>}>
                            <Descriptions bordered size='small' column={1} style={{maxWidth:500}}>
                                <Descriptions.Item label={t('organizer-id','Organizer ID')}>
                                    <Typography.Text code copyable={{tooltips: t('tooltip.copy-lightroom-publish-organizer-id','Copy Lightroom Publish Organizer ID'), text: c.id}} onClick={e => e.stopPropagation()}>
                                        {c.id}
                                    </Typography.Text>
                                </Descriptions.Item>

                                {(c.editable || c.description?.length) && (
                                    <Descriptions.Item label={'Description'}>
                                        <Typography.Paragraph
                                            editable={c.editable && {onChange: onChangeDescription}}
                                        >
                                            {description || ''}
                                        </Typography.Paragraph>
                                    </Descriptions.Item>
                                )}

                                {collection.user_group && (
                                    <Descriptions.Item label='User Group'> <UserGroupIcon/> {collection.user_group.name} </Descriptions.Item>
                                )}

                                {(c.editable || collection.links?.length) && (
                                    <Descriptions.Item label={'Links'}>
                                        <Links what={collection} type={'AssetGroup'} id={collection.asset_group_id}/>
                                    </Descriptions.Item>
                                )}

                                <Descriptions.Item label='Created'> <TimeAgo date={c.created_at}/> by <User user={c.user}/></Descriptions.Item>

                                {(c.publish_type == 'lightroom') && (
                                    <Descriptions.Item label={'Publish Manager'}>
                                        <User user={c.publish_user}/>
                                        {c.publish_user?.id == currentUser?.id && (
                                            <LightroomConnectionButton storageFolder={c}/>
                                        )}
                                    </Descriptions.Item>
                                )}

                                <Descriptions.Item label={t('organization-id','Organization ID')}>
                                    <Typography.Text code copyable={{tooltips: t('tooltip.copy-organization-id','Copy Organization ID'), text: currentOrg?.id}} onClick={e => e.stopPropagation()}>
                                        {currentOrg?.id}
                                    </Typography.Text>
                                </Descriptions.Item>
                                <Descriptions.Item label={t('publish-service-id','Publish Service ID')}>
                                    {c.lightroom_publish_service_id ? (
                                        <Typography.Text code copyable={{tooltips: t('tooltip.copy-lightroom-publish-service-id','Copy Lightroom Publish Service ID'), text: c.lightroom_publish_service_id}} onClick={e => e.stopPropagation()}>
                                            {c.lightroom_publish_service_id}
                                        </Typography.Text>
                                    ) : (
                                        <em>{t('not-set-yet','Not set yet.')}</em>
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                        </Collapse.Panel>
                    </Collapse>
                )}

                {!viewOnly && (
                    <Space direction={'horizontal'}>
                        {showAddButton && (
                            <AddAssetGroup
                                sandbox={sandbox}
                                parent={c}
                            />
                        )}

                        {isStorageFolder && (
                            <DuplicateToCollectionButton storageFolder={c}/>
                        )}

                        {isStorageFolder && (
                            <DownloadStorageFolderButton storageFolder={c}/>
                        )}

                        {isCollection && (
                            <DuplicateToLightboxButton collection={c}/>
                        )}

                        {c.type !== 'StorageFolder' && ability.can('clone','AssetGroup') && (
                            <CloneButton assetGroup={c}/>
                        )}

                        {showArchiveButton && ( ArchiveButton )}

                        {showDeleteButton && ( DeleteButton )}
                    </Space>
                )}

                {collection.permissions?.length && (
                    <List
                        header={<strong><LockOutlined/> {t('permissions','Permissions')}</strong>}
                        size={'small'}
                        bordered
                        dataSource={collection.permissions}
                        renderItem={perm => (
                            <List.Item>
                                <strong>{perm.inherited && `${t('inherited','Inherited')}: `}{perm.user_group.name}</strong>
                                &nbsp;
                                {perm.name}
                                {perm.watermark && (
                                    <Tooltip title={t('watermarked','Watermarked')} style={{zIndex:4000}}><Tag style={{margin:'0 .5em'}}><WatermarkIcon/></Tag></Tooltip>
                                )}
                            </List.Item>
                        )}
                    />
                ) || ''}

                {_.reject(collection.asset_group_admins || {}, {role_level:'none'}).length && (
                    <List
                        header={<strong><LockOutlined/> {t('admins','Admins')}</strong>}
                        size={'small'}
                        bordered
                        dataSource={_.reject(collection.asset_group_admins, {role_level:'none'})}
                        renderItem={aga => (
                            <List.Item>
                                {aga.inherited && <>{t('inherited','Inherited')}: &nbsp;</>}
                                <strong>{aga.user_group?.name}</strong>
                                &nbsp;
                                {t(aga.role_level_name,aga.role_level_name)}
                                &nbsp;
                                {aga.children_inherit && <em>{t('children-inherit','Children Inherit')}</em>}
                            </List.Item>
                        )}
                    />
                ) || ''}

                {collection.type === 'Lightbox' && !!collection.users?.length && (
                    <List
                        header={<strong><UserGroupIcon/> {t('members','Members')}</strong>}
                        size={'small'}
                        bordered
                        dataSource={collection.users}
                        renderItem={user => (
                            <List.Item>
                                <Space>
                                    <User user={user}/>
                                    <Tag>
                                        {user.id === collection.user?.id ? <>{t('owner','Owner')}</> : (
                                            <>{user.editable ? <><EditOutlined/> {t('view-edit','View/Edit')}</> : <><EyeOutlined/> {t('view-only','View Only')}</>}</>
                                        )}
                                    </Tag>
                                </Space>
                            </List.Item>
                        )}
                    />
                )}

                {!!collection.workflow_steps?.length && (
                    <List
                        header={<strong><WorkflowIcon/> {t('workflows','Workflows')}</strong>}
                        size={'small'}
                        bordered
                        dataSource={collection.workflow_steps}
                        renderItem={step => {
                            const [formVisible, setFormVisible] = useState(false)

                            const edit = ()=> {
                                setVisible(false)
                                setFormVisible(true);
                            }

                            const onClose = ()=> setFormVisible(false)

                            return (
                                (<List.Item>
                                    <strong>{t('name', 'Name')}:</strong> {step.workflow.name}
                                    {step.destination && (
                                        <>
                                            <br/>
                                            <strong>{t('step', 'Step')}:</strong> {step.name}
                                        </>
                                    )}
                                    {step.source && (
                                        <>
                                            <br/>
                                            <strong>{t('source', 'Source')}:</strong> <AssetGroupLink assetGroup={step.source}/>
                                        </>
                                    )}
                                    {step.destination && (
                                        <>
                                            <br/>
                                            <strong>{t('destination', 'Destination')}:</strong> <AssetGroupLink assetGroup={step.destination}/>
                                        </>
                                    )}
                                    {step.editable && (
                                        <>
                                            <br/>
                                            <Button onClick={edit} size={'small'} style={{marginTop:'1em'}}>
                                                <EditOutlined/> {t('button-edit', 'Edit')}

                                                <WorkflowForm
                                                    id={step.workflow_id}
                                                    stepId={step.id}
                                                    open={formVisible}
                                                    onClose={onClose}
                                                    onSave={onClose}
                                                />
                                            </Button>
                                        </>
                                    )}
                                </List.Item>)
                            );
                        }}
                    />
                )}

                {!!collection.webhooks?.filter(wh => wh.enabled)?.length && (
                    <List
                        header={<strong><ApiOutlined/> {t('webhooks','Webhooks')}</strong>}
                        size={'small'}
                        bordered
                        dataSource={collection.webhooks.filter(wh => wh.enabled)}
                        renderItem={wh => (
                            <List.Item>
                                <Tooltip title={wh.url}><strong>{wh.name}</strong></Tooltip> {t('by','by')} <User user={wh.user}/>
                            </List.Item>
                        )}
                    />
                )}

                {loading && <LoadingOutlined/>}
            </VerticalSpace>
        </Modal>
    )

    if(contextMenu) {
        const {
            icon: addIcon,
            label: addLabel,
            onClick: clickAdd,
            modal: addModal,
        } = useAddAssetGroup({sandbox, parent: c, menu: true})

        return (
            <div>
                <div onClick={e => e.stopPropagation()} >
                    {modal}
                </div>

                {addModal}

                {/*TODO: refactor use `items` */}
                <Dropdown
                    trigger={['contextMenu']}
                    arrow
                    overlay={
                        <Menu
                            style={{minWidth:150}}
                        >
                            <Menu.Item key={'details'} >
                                <a onClick={e=> {
                                    e.stopPropagation()
                                    setVisible(!visible);
                                }}>
                                    <InfoCircleOutlined/> {t('details-ellipses','Details...')}
                                </a>
                            </Menu.Item>
                            {showManage && (
                                <Menu.Item key={'manage'}>
                                    <a onClick={e => {
                                        e.stopPropagation()
                                        clickManage(e, c);
                                    }}>
                                        <SettingOutlined/> {t('manage-ellipses','Manage...')}
                                    </a>
                                </Menu.Item>
                            )}

                            {isStorageFolder && (
                                <Menu.Item key={'download'}>
                                    <DownloadStorageFolderButton storageFolder={c} menu/>
                                </Menu.Item>
                            )}

                            <Menu.Divider/>
                            {showAddButton && (
                                <Menu.Item key={'add'}>
                                    <a onClick={e => {
                                        e.stopPropagation()
                                        clickAdd(e)
                                    }}>
                                        <Flex align="center" gap={4}>
                                            {addIcon} {addLabel}
                                        </Flex>
                                    </a>
                                </Menu.Item>
                            )}

                            {isStorageFolder && (
                                <Menu.Item key={'duplicate'}>
                                    <DuplicateToCollectionButton storageFolder={c} menu/>
                                </Menu.Item>
                            )}

                            {isCollection && (
                                <Menu.Item key={'duplicate'}>
                                    <DuplicateToLightboxButton collection={c} menu/>
                                </Menu.Item>
                            )}

                            {c.type !== 'StorageFolder' && ability.can('clone','AssetGroup') && (
                                <Menu.Item key={'clone'}>
                                    <CloneButton assetGroup={c} menu/>
                                </Menu.Item>
                            )}

                            {(showDeleteButton || showArchiveButton) && <Menu.Divider/>}

                            {showArchiveButton && <Menu.Item key={'archive'}>{ArchiveButton}</Menu.Item>}
                            {showDeleteButton && <Menu.Item key={'delete'} disabled={deleteButtonDisabled}>{DeleteButton}</Menu.Item>}
                        </Menu>
                    }
                >
                    {children}
                </Dropdown>
            </div>
        )
    }

    return (
        <span onClick={e => e.stopPropagation()}>
            {modal}

            <Tooltip title={t('details', 'Details')}>
                <Button
                    id={`asset-group-details-btn-${collection.asset_group_id}`}
                    onClick={()=> setVisible(true)}
                    type={'text'}
                    shape={'circle'}
                    size={'small'}
                    aria-label={t('view-details','View Details')}
                    tabIndex={viewOnly ? 0 : -1}
                >
                    {children || <InfoCircleOutlined style={{color:'rgb(176, 176, 176)'}} id={`asset-group-info-${collection.asset_group_id}`}/>}

                    {collection.user_group_id && collection.organizer && (
                        <span style={{color:'rgb(176, 176, 176)'}}>
                            <UserGroupIcon />
                        </span>
                    )}

                    {collection.embargoed && (
                        <span style={{color:'rgb(176, 176, 176)'}}>
                            <Tooltip title={t('embargoed','Embargoed')}><EyeInvisibleOutlined/></Tooltip>
                        </span>
                    )}
                </Button>

                {!!collection.has_workflow_steps && <Tooltip title={t('has-workflows','Has Workflows')}><WorkflowIcon style={{marginLeft:'.5em'}}/></Tooltip>}
                {(!!collection.box_folder_id || !!collection.dropbox_folder_id || !!collection.frame_project_id || !!collection.google_drive_folder_id) && <Tooltip title={t('has-integration', 'Has Integrations')}><ApiOutlined style={{marginLeft:'.5em'}}/></Tooltip>}
            </Tooltip>
        </span>
    )
}

export {AssetGroupInfoPopover};


const FrameProjectPickerButton = ({onPick, connecting})=> {
    const {t} = useTranslation();

    const [visible, setVisible] = useState();

    const [loading, setLoading ] = useState()
    const [teams, setTeams ] = useState([])
    const [error, setError] = useState()

    useEffect(()=>{
      if(visible && !loading) {
          setLoading(true)
          api(`/api/integrations/frame_projects`).then(res => {
              setLoading(false)
              if(res.data) {
                  setTeams(res.data)
              } else {
                  setError(true)
              }
          })
      }
    }, [visible]);

    useEffect(()=>{
        setError(false)
    }, [visible])

    const click = ()=> setVisible(true)
    const cancel = ()=> setVisible(false)

    const choose = (team, project) => {
        setVisible(false)
        onPick({team,project, frame_include_videos: checkboxRef.current.state.checked})
    }

    const checkboxRef = useRef()

    return (<>
        <Button size={'small'} onClick={click} icon={<FrameIcon style={{marginLeft:0, marginRight:3}}/>} loading={connecting}>{t('connect-frame-btn','Choose Project on Frame.io')}</Button>
        <Modal
            open={visible}
            title={<><ApiOutlined/> {t('select-frame-project','Select Project on Frame.io')}</>}
            footer={null}
            onCancel={cancel}
            width={'50%'}
            zIndex={9998}
        >
            <VerticalSpace size={'large'}>
                <Checkbox name={'frame_include_videos'} ref={checkboxRef}>{t('frame-include-full-video-files','Include Full Resolution Video Files?')} <HelpPopover code={'frame_include_videos'}/></Checkbox>

                <Skeleton loading={loading} active>
                        {error ? (<Alert type={'error'} message={t('frame-connect-error','There was an error loading your Teams, please reconnect your Frame.io account.')}/>) :
                            (
                                <Collapse>
                                    {teams.map((team,i)=> (
                                        <Collapse.Panel key={i} header={<>Team:&nbsp;<strong>{team.name}</strong>&nbsp;<GreyBadge count={team.projects.length}/></>}>
                                            <List
                                                bordered
                                                size={'small'}
                                            >
                                                {team.projects?.map((project,i) => (
                                                    <List.Item
                                                        key={i}
                                                        actions={
                                                            [
                                                                <Popconfirm title={'Choose Project?'} onConfirm={()=> choose(team, project)} zIndex={9999}>
                                                                    <Button icon={<CheckOutlined/>} type={'primary'}>Choose</Button>
                                                                </Popconfirm>
                                                            ]
                                                        }
                                                    >
                                                        <List.Item.Meta
                                                            avatar={<img src={project.root_asset?.cover_asset?.thumb}/>}
                                                            title={
                                                                <Tooltip title={'View on Frame.io'} zIndex={10000}>
                                                                    <a href={`https://app.frame.io/projects/${project.id}`} target={'_blank'} style={{textDecoration:'underline'}}>{project.name}</a>
                                                                </Tooltip>
                                                            }
                                                            description={
                                                                <>
                                                                    {t('folders','Folders')}: {project.folder_count}
                                                                    <br/>
                                                                    {t('files','Files')}: {project.file_count}
                                                                </>
                                                            }
                                                        />
                                                    </List.Item>
                                                ))}
                                            </List>
                                        </Collapse.Panel>
                                    ))}
                                </Collapse>
                            )
                        }
                </Skeleton>
            </VerticalSpace>
        </Modal>
    </>);
}

export {FrameProjectPickerButton}

const BulkMoveAssetGroupModal = ({afterMove, open: editModalVisible, setOpen: setEditModeVisible})=>{
    const {t} = useTranslation();
    const {checkedIds, type} = useContext(TreeContext);

    const clickMove = ()=>{
        setEditModeVisible(true)
    }

    const bulkJobDispatch = useBulkJobsDispatch()
    const [bulkJobId, setBulkJobId] = useState()

    const assetGroupDispatch = useAssetGroupDispatch();

    const onBulkMoveFinish = ()=>{
        setEditModeVisible(false)
        setBulkJobId(null)

        switch(type) {
            case 'lightboxes':
                return assetGroupDispatch({type:'reloadLightboxes'})
            case 'collections':
                return assetGroupDispatch({type:'reloadCollections'})
            case 'storage_folders':
                return assetGroupDispatch({type:'reloadStorageFolders'})
        }

        message.success(t('message-done','Done!'))

        afterMove && afterMove()
    }

    const selectableIf = assetGroup => {
        if(checkedIds.indexOf(assetGroup.asset_group_id) != -1) return false;
        if(type == 'collections' || type == 'storage_folders') return true;
        if(type == 'lightboxes') return (assetGroup.organizer || assetGroup.sub_type == 'project');
    }

    return (<>
        <Formik
            initialValues={{}}
            onSubmit={(values, actions) => {
                actions.resetForm()
                actions.setSubmitting(false)

                const data = {
                    bulk_job: {
                        asset_group_ids: checkedIds,
                        new_asset_group_parent_id: values.asset_group_id,
                    }
                }

                // Create BulkJob and listen:
                message.info({content: t('message-starting-bulk-job','Starting Bulk Job...'), key:'bulkJob'})

                api.post(`/api/bulk_jobs`, data).then(res => {
                    setBulkJobId(res.data.guid)
                    bulkJobDispatch({type:'add', bulkJob: res.data})
                }).catch((err)=>{
                    console.log(err)
                })
            }}
        >
            {({submitForm, isSubmitting, values})=>(
                <Modal
                    open={editModalVisible}
                    onCancel={()=> setEditModeVisible(false)}
                    title={<>{t('move-items-into','Move {{count}} Items Into...', {count: checkedIds?.length})}</>}
                    footer={
                        <Space direction={'horizontal'}>
                            <Popconfirm title={'Move?'} onConfirm={submitForm} >
                                <Button type={'primary'} disabled={isSubmitting || !values.asset_group_id}>
                                    <SaveOutlined/>
                                    {t('button-save','Save')}
                                </Button>
                            </Popconfirm>
                            <Button onClick={() => setEditModeVisible(false)} disabled={isSubmitting}>{t('button-cancel','Cancel')}</Button>
                        </Space>
                    }
                >
                    <div id={'move-asset-groups-modal'}>
                        {bulkJobId ?
                            <AssetGroupBulkJobProgress bulkJobId={bulkJobId} onFinish={onBulkMoveFinish}/> :
                            <AssetGroupChooser
                                type={type}
                                fieldName={'asset_group_id'}
                                organizerSelection
                                readOnly
                                selectableIf={selectableIf}
                            />
                        }
                    </div>
                </Modal>
            )}
        </Formik>
    </>);
}

const ChildrenSortOrder = ({c})=>{
    const {t} = useTranslation();

    const def = 'name-asc'
    const value = c.children_sort_order || def
    const [childrenSortOrder, setChildrenSortOrder] = useState(value)

    let display;
    const [r, field, direction] = (childrenSortOrder || def).match(/^(.+?)-(.+?)$/) || []

    // name size updated_at created_at custom
    switch(field) {
        case 'name':
        case 'size':
            display = t(field, field).toProperCase()
            break
        case 'updated_at':
            display = t('last-update', 'Last Update')
            break
        case 'created_at':
            display = t('created', 'Created')
            break
    }

    const directionDisplay = direction === 'asc' ? t('ascending', 'Ascending') : t('descending', 'Descending')

    return (
        <Space>
            {display} - {directionDisplay.toProperCase()}

            <Popover
                trigger={['click']}
                placement={'right'}
                content={
                    <Formik
                        initialValues={{children_sort_order: value}}
                        enableReinitialize={true}
                        onSubmit={(values, actions) => {
                            actions.setSubmitting(true)
                            const field = {
                                'Collection': 'collection',
                                'StorageFolder': 'storage_folder',
                                'Lightbox': 'lightbox'
                            }[c.type];

                            const route = {
                                'Collection': 'collections',
                                'StorageFolder': 'storage_folders',
                                'Lightbox': 'lightboxes'
                            }[c.type];

                            let data = {}
                            data[field] = values;

                            api({ method: 'put', url: `/api/${route}/${c.id}`, data: data }).then((res)=>{
                                actions.setSubmitting(false)
                                message.success(`${c.name} ${t('updated','Updated')}!`)
                                setChildrenSortOrder(values.children_sort_order)

                            }).catch((error)=>{
                                console.error(error)
                                message.error(`Error: ${JSON.stringify(error.data)}`)
                                actions.setSubmitting(false)
                            })
                        }}
                    >
                        {({submitForm, dirty, isSubmitting})=> (
                            <>
                                <ChildrenSortFormItem assetGroup={c}/>

                                <Button type={'primary'} onClick={submitForm} disabled={!dirty} loading={isSubmitting} ghost block>
                                    <SaveOutlined/>
                                    {t('save','Save')}
                                </Button>
                            </>
                        )}
                    </Formik>
                }
            >
                <EditOutlined tabIndex={0} style={{color: Colors.blue}} id={`edit-child-sort-order-btn-${c.id}`}/>
            </Popover>
        </Space>
    )
}
import React, {useState, useRef} from "react";
import {Button, Space, message, Modal, Input as AntInput, Tooltip, Tabs} from "antd";
import {PlusOutlined, SaveOutlined} from "@ant-design/icons";
import {RefreshIcon} from "../helpers/icons";
import setTitle from "../helpers/setTitle";
import {Can} from "../helpers/Can";
import CreatorTagsTable from "./CreatorTagsTable";
import {useTranslation} from "react-i18next";
import {Formik} from "formik";
import api from "../api";
import * as Yup from "yup";
import {Form, FormItem, Input} from "formik-antd";
import FloatLabel from "../helpers/FloatLabel";
import Content from "../Content";

const { Search } = AntInput;
const { TabPane } = Tabs;

export default () => {
    const {t} = useTranslation();
    setTitle(t('creator-tags','Creator Tags'));

    const [searchValue, setSearchValue] = useState()
    const [searchLoading, setSearchLoading] = useState(false)
    const [refresh, setRefresh] = useState(0)
    const [tab, setTab] = useState('browse');
    const [newCreatorTag, setNewCreatorTag] = useState()

    const onSearch = _.debounce((value) => {
        setSearchLoading(true);
        setSearchValue(value);
    }, 250)

    const onChange = (e) => {
        e.persist()
        onSearch(e.target.value)
    }

    const onLoaded = () => {
        setSearchLoading(false);
    }

    const onSave = creatorTag => {
        setNewCreatorTag(creatorTag)
    }

    const Extra = (
        <Space>
            <Search
                placeholder={t('placeholder-search-creator-tags','Search Creator Tags...')}
                onSearch={onSearch}
                style={{width:'100%'}}
                size={'medium'}
                allowClear
                loading={searchLoading}
                onChange={onChange}
            />
            <Can I={'create'} a={'CreatorTag'}>
                <NewCreatorTagButton onSave={onSave}/>
            </Can>
            <Tooltip title={t('tooltip.reload-creator-tags','Reload Creator Tags')}>
                <Button icon={<RefreshIcon/>} type='text' onClick={() => setRefresh(refresh + 1)}/>
            </Tooltip>
        </Space>
    )

    return (
        <>
            <Tabs
                activeKey={tab}
                onTabClick={(key) => setTab(key)}
                tabBarExtraContent={Extra}>
                <TabPane tab={t("about","About")} key="about">
                    <Content code={'about-creator-tags'}/>
                </TabPane>
                <TabPane tab={t("browse-creator-tags","Browse Creator Tags")} key="browse">
                    <CreatorTagsTable q={searchValue} onLoaded={onLoaded} newCreatorTag={newCreatorTag} refresh={refresh}/>
                </TabPane>
            </Tabs>
        </>
    );
}

const NewCreatorTagButton = ({onSave}) => {
    const {t} = useTranslation();
    const [modalVisible, setModalVisible] = useState(false)
    const autoFocusInput = useRef(null);

    const onClick = () => {
        setModalVisible(true)
        setTimeout(() => autoFocusInput.current?.focus(), 100)
    }

    return (
        <>
            <Button type='primary' onClick={onClick}>
                <PlusOutlined/>
                {t('button-add-new-creator-tag','Add New Creator Tag')}
            </Button>
            <Formik
                initialValues={{}}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    const data = {creator_tag: values}

                    api.post(`/api/creator_tags/`, data).then(res => {
                        actions.resetForm();
                        actions.setSubmitting(false);

                        setModalVisible(false);
                        message.success(t('message-creator-tag-created','Creator Tag created.'))

                        onSave && onSave(res.data)
                    }).catch((err) => {
                        message.error(JSON.stringify(err.response.data))
                    })
                }}
                validationSchema={
                    Yup.object({
                        name: Yup.string().required(''),
                    })
                }
            >
                {({values, submitForm, handleSubmit}) => {
                    const onKeyDownCapture = (e) => {
                        if(e.keyCode == 13) {
                            e.stopPropagation();
                            e.preventDefault();
                            submitForm();
                        }
                    }

                    return (
                        <Modal
                            title={t('new-creator-tag','New Creator Tag')}
                            open={modalVisible}
                            destroyOnClose
                            onCancel={() => setModalVisible(false)}
                            centered
                            confirmLoading
                            footer={
                                <Space direction={'horizontal'}>
                                    <Button type={'primary'} onClick={submitForm}>
                                        <SaveOutlined/>
                                        {t('button-create','Create')}
                                    </Button>
                                    <Button onClick={() => setModalVisible(false)}>{t('button-cancel','Cancel')}</Button>
                                </Space>
                            }
                        >
                            <form onSubmit={handleSubmit} onKeyDownCapture={onKeyDownCapture}>
                                <FormItem required name='name' showValidateSuccess>
                                    <FloatLabel label={t('name','Name')} name={'name'} value={values?.name} description={t('placeholder-creator-tag-name','e.g. Influencer')}>
                                        <Input size={'large'} required name='name' ref={autoFocusInput} autoFocus autoComplete='off'/>
                                    </FloatLabel>
                                </FormItem>
                                <FormItem name='description' style={{marginTop:'.5em'}}>
                                    <FloatLabel label={t('description','Description')} name={'description'} value={values.description} description={t('optional','Optional.')}>
                                        <Input.TextArea rows={2} name='description'/>
                                    </FloatLabel>
                                </FormItem>
                            </form>
                        </Modal>
                    );
                }}
            </Formik>
        </>
    );
}
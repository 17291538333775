import React, {useContext, useEffect, useState} from "react";
import {useAggsState} from "../../contexts/AggsContext";
import {useFilters} from "../helpers/useFilters";
import {
    Avatar,
    Badge,
    Button,
    Collapse,
    Descriptions, Divider, Flex,
    message, Popconfirm,
    Popover,
    Select, Skeleton,
    Space,
    Tag,
    Tooltip,
    Typography
} from "antd";
import {
    CheckCircleOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined, LoadingOutlined, PlusOutlined,
    QuestionOutlined,
    UserOutlined
} from "@ant-design/icons";

import _ from 'lodash';
import Links from "../widgets/Links";
import {useAssetsDispatch} from "../../contexts/AssetsContext";
import {useDrop} from "react-dnd";
import api from "../api";
import {useSelectedAssetsDispatch, useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import User from "../helpers/User";
import UserSelect from "../widgets/UserSelect";
import {Formik} from "formik";
import {SessionContext} from "../../contexts/SessionContext";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {AppContext} from "../../contexts/AppContext";
import {DeleteIcon, GreyBadge} from "../helpers/icons";
import {useTranslation} from "react-i18next";
import {useAbility} from "@casl/react";
import {AbilityContext} from "@/components/helpers/Can";
import {EditableTag} from "@/components/widgets/TagSelect";


export default ({afterConvert})=> {
    const {t} = useTranslation();
    const {state: sessionState} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    const {aggs} = useAggsState();
    const {filters, setFilters} = useFilters();

    const ids = aggs?.creator_tag_id?.buckets?.map(agg => agg.key)

    const [creatorTags, setCreatorTags] = useState([])

    const [loading, setLoading] = useState()
    useEffect(()=>{
        if(!ids || !ids.length) return;

        setLoading(true)
        api.post('/api/creator_tags/find', {ids}).then(res => {
            setLoading(false)
            setCreatorTags(res.data)
        })
    }, [aggs, currentOrg?.slug])

    const unsaved = aggs?.unsaved_creator?.buckets || []

    const missingCount = aggs?.no_creator?.doc_count
    const missingApplied = filters.no_creator
    const clickNoCreator = ()=>{
        setFilters(missingApplied, {no_creator: true})
    }

    const ability = useAbility(AbilityContext);

    const [converting, setConverting] = useState([])

    return (
        <Skeleton active loading={loading}>
            <Flex wrap={'wrap'} gap={'small'}>
                {!creatorTags.length && <Typography.Text type={'secondary'}>{t('none-yet','None yet...')}</Typography.Text>}

                {creatorTags.map((cTag) => {
                    const applied = filters.creator_tag_id && filters.creator_tag_id.indexOf(cTag.id.toString()) != -1

                    const onClick = ()=>{
                        setFilters(applied, {creator_tag_id: cTag.id.toString()})
                    }

                    const badgeCount = _.find(aggs.creator_tag_id.buckets, {key: cTag.id})?.doc_count

                    const props = {cTag, onClick, applied, badgeCount}

                    return <CreatorTag key={cTag.id} showBadge {...props}/>
                })}

                {!!unsaved.length && (
                    <Flex wrap={'wrap'} gap={'small'}>
                        <br/>
                        {unsaved.map(doc => {
                            const applied = filters.unsaved_creator && filters.unsaved_creator.indexOf(doc.key) != -1
                            const onClick = ()=>{
                                setFilters(applied, {unsaved_creator: doc.key})
                            }

                            const clickConvert = ()=>{
                                setConverting(converting => [...converting, doc.key])
                                api.post(`/api/creator_tags`, {creator_tag:{name: doc.key, bulk: true}}).then(res => {
                                    // Allow reindex:
                                    setTimeout(()=>{
                                        setConverting(converting => _.without(converting, doc.key))
                                        message.success('Done Converting!')
                                        afterConvert && afterConvert()
                                    }, 1000)
                                })
                            }

                            if(converting.indexOf(doc.key) !== -1) {
                                return <Tag icon={<LoadingOutlined/>}>Converting...</Tag>
                            }

                            const TagComponent = (
                                <Tag
                                    key={doc.key}
                                    onClick={onClick}
                                    color={applied ? 'blue' : null}
                                    style={{cursor: 'pointer', marginBottom: '.5em'}}
                                    tabIndex={0}
                                    onKeyDown={e => e.keyCode === 13 && onClick()}
                                >
                                    {doc.key} <GreyBadge count={doc.doc_count}/>
                                </Tag>
                            )

                            if(ability.can('create', 'CreatorTag')) {
                                return (
                                    <Popover
                                        placement={'right'}
                                        content={
                                            <Popconfirm onConfirm={clickConvert} title={'Convert?'}>
                                                <Button icon={<PlusOutlined/>} type={'primary'} ghost>{t('convert-to-verified-creator-tag','Convert to Verified Creator Tag')}</Button>
                                            </Popconfirm>
                                        }
                                    >
                                        {TagComponent}
                                    </Popover>
                                );
                            } else {
                                return TagComponent
                            }

                        })}
                    </Flex>
                )}

                {missingCount > 0 && (
                    <Tag
                        onClick={clickNoCreator}
                        icon={<QuestionOutlined/>}
                        color={missingApplied ? 'blue' : null}
                        style={{cursor: 'pointer', marginBottom: '.5em'}}
                        tabIndex={0}
                        onKeyDown={e => e.keyCode === 13 && clickNoCreator()}
                    >
                        <em>{t('blank','Blank')}</em> <GreyBadge count={missingCount}/>
                    </Tag>
                )}
            </Flex>
        </Skeleton>
    )
}

const CreatorTag = ({ cTag, onClick, applied, showBadge, badgeCount, asset, editable, width, afterUpdate, afterInfoUpdate, placeholder, children, trigger = 'hover' }) => {
    const {t} = useTranslation();

    const [loading, setLoading] = useState()
    const [count, setCount] = useState()

    useEffect(()=> {
        setCount(badgeCount)
    }, [badgeCount])

    const [details, setDetails] = useState({})

    cTag = {...cTag, ...details};

    const getInfo = (visible)=>{
        if(!visible) return

        setLoading(true)
        api(`/api/creator_tags/${cTag.id}`).then(res => {
            setDetails(res.data)
            setLoading(false)
        })
    }

    // If only the ID is passed, fetch the details.
    useEffect(()=>{
        if(!cTag.name) getInfo(true)
    }, [cTag.name])

    const onChange = (field, cb)=> {
        return (val)=> {
            console.log('onChange', field, val)
            const data = {}
            data[field] = val
            api.put(`/api/creator_tags/${cTag.id}`, {creator_tag: data}).then(res => {
                setDetails(res.data)
                afterInfoUpdate && afterInfoUpdate()
                cb && cb()
            })
        }
    }

    // TODO: extract
    const onStart = ()=>{
        setTimeout(()=> {
            const list = document.querySelectorAll("div.ant-typography-edit-content > textarea.ant-input")
            for(let input of list) {
                input.addEventListener("keydown", (e) => {
                    if (e.keyCode == 8){
                        e.stopPropagation()
                    }
                })
            }
        },100)
    }

    const update = (newDetails)=> {
        setDetails(newDetails)
    }

    //--------------------
    // Asset Drop Handling (enabled only on search)
    //--------------------
    const {selectedAssetIds} = useSelectedAssetsState();
    const assetsDispatch = useAssetsDispatch();
    const selectedAssetsDispatch = useSelectedAssetsDispatch()

    const [{canDrop, isOver}, drop] = !showBadge ? [{}] : useDrop({
        accept: 'asset',
        drop: ({asset}) => {
            const ids = _.uniq([asset.id, ...selectedAssetIds]);
            api.post('/api/assets/set_creator_tag', {ids: ids, creator_tag_id: cTag.id }).then(res => {
                setTimeout(()=> {
                    assetsDispatch({type:'reload'});
                    selectedAssetsDispatch({type:'reload'});
                    // refresh()
                }, 500)
                setCount(res.data.count)
                message.success(`${ids.length} asset${ids.length > 1 ? 's' : ''} set to ${cTag.name}`)
            })
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        hover: (item) => {
            // console.log('hovering', props.title);
        }
    });

    const isActive = canDrop && isOver;

    const style = isActive ? {
        border: '1px solid #b7eb8f',
        backgroundColor: '#f6ffed'
    } : {};

    let Icon;
    if(cTag.linked_user?.avatar_url) {
        Icon = <Avatar src={cTag.linked_user.avatar_url} size={'small'} style={{marginRight:'.5em', height:17, width:17}}/>
    } else if(cTag.linked_tag?.thumb_url) {
        Icon = (
            <Popover content={<Avatar src={cTag.linked_tag.thumb_url} size={200}/>} placement={'left'}>
                <Avatar src={cTag.linked_tag.thumb_url} size={20} style={{marginRight:'.5em'}}/>
            </Popover>
        )
    } else {
        Icon = <UserOutlined/>
    }

    //--------------------

    return (
        <span ref={drop}>
            {cTag?.id && (
                <Popover
                    overlayStyle={{width: isMobile() ? '100%' : 500}}
                    trigger={trigger}
                    placement={'right'}
                    title={<><UserOutlined/> Creator</>}
                    onOpenChange={getInfo}
                    content={
                        <Space direction={'vertical'} style={{width:'100%'}}>
                            <Descriptions bordered size='small' column={1}>
                                <Descriptions.Item label={t('name','Name')}>
                                    <div className={'editable-name'}>
                                        <Typography.Text editable={cTag.editable && {onChange: onChange('name'), onStart}}>
                                            {cTag.name}
                                        </Typography.Text>
                                    </div>
                                </Descriptions.Item>
                                <Descriptions.Item label={t('description','Description')}>
                                    <Typography.Text editable={cTag.editable && {onChange: onChange('description'), onStart}}>
                                        {cTag.description}
                                    </Typography.Text>
                                </Descriptions.Item>
                                <Descriptions.Item label={t('links','Links')}>
                                    <Links what={cTag} type={'CreatorTag'}/>
                                </Descriptions.Item>
                                <Descriptions.Item label={t('user','User')}>
                                    {!cTag.editable && cTag.linked_user && (
                                        <User user={cTag.linked_user}/>
                                    )}

                                    {cTag.editable && (
                                        <Formik
                                            initialValues={{linked_user_id: cTag.linked_user?.id}}
                                            onSubmit={(values)=>{
                                                if(values.linked_user_id) {
                                                    onChange('linked_user_id')(values.linked_user_id, ()=> {
                                                        message.success(t('message-linked-user-updated.','Linked User Updated.'))
                                                    })
                                                } else {
                                                    api.put(`/api/creator_tags/${cTag.id}`, {creator_tag: {linked_user_id: ''}}).then(res => {
                                                        setDetails(res.data)
                                                        message.success(t('message-linked-user-removed.','Linked User Removed.'))
                                                    })
                                                }
                                            }}
                                        >
                                            {({submitForm})=> (
                                                <UserSelect
                                                    selectedUser={cTag.linked_user}
                                                    fieldName={'linked_user_id'}
                                                    size={'middle'}
                                                    style={{width:300}}
                                                    onChange={submitForm}
                                                    allowClear
                                                />
                                            )}
                                        </Formik>
                                    )}
                                </Descriptions.Item>

                                {cTag.linked_tag && (
                                    <Descriptions.Item label={t('face','Face')}>
                                        <EditableTag tag={cTag.linked_tag} bulk large disableRemove disableTypeSelect/>
                                    </Descriptions.Item>
                                )}
                            </Descriptions>

                            {cTag.editable && (
                                <Collapse>
                                    <Collapse.Panel header={t('contact-info','Contact Info')} key={'contact-info'}>
                                        <Descriptions bordered size='small' column={1}>
                                            {Object.keys(MetaFields.creator_contact_info.struct).map(f => {
                                                const attrs = MetaFields.creator_contact_info.struct[f]

                                                return (
                                                    <Descriptions.Item key={f} label={attrs.name}>
                                                        <Typography.Text editable={cTag.editable && {
                                                            onChange: onChange(attrs.creator_tag_field),
                                                            onStart
                                                        }}>
                                                            {cTag[attrs.creator_tag_field]}
                                                        </Typography.Text>
                                                    </Descriptions.Item>
                                                );
                                            })}
                                        </Descriptions>
                                    </Collapse.Panel>
                                </Collapse>
                            )}
                        </Space>
                    }
                >
                    {children ? (
                        children
                    ) : (
                            <Tag
                                key={cTag.id}
                                color={applied ? 'blue' : null}
                                style={{ ...style, marginBottom: '.5em', cursor: onClick ? 'pointer' : 'auto' }}
                                onClick={onClick}
                                icon={
                                    <>
                                        <CheckCircleOutlined style={{ color: Colors.blue }} />
                                        {Icon}
                                    </>
                                }
                            >
                                {cTag.name} {showBadge && <Badge count={count} style={{ backgroundColor: '#fff', color: '#999' }} />}
                            </Tag>
                    )}
                </Popover>
            )}

            {editable && <CreatorTagSelect asset={asset} onUpdate={update} width={width} afterUpdate={afterUpdate} placeholder={placeholder}/>}
        </span>
    )
}

const CreatorTagSelect = ({asset, onUpdate, width, afterUpdate, placeholder})=> {
    const {t} = useTranslation();
    const {state} = useContext(AppContext);
    const {currentUser} = state;

    const [visible, setVisible] = useState()
    const [loading, setLoading] = useState()
    const [value, setValue] = useState()

    const defaultTags = [
        { name: currentUser.name, id: 'me' },
        { name: t('unknown','Unknown'), id: 'unknown' }
    ]

    const [creatorTags, setCreatorTags] = useState(defaultTags)

    const options =
        creatorTags
            .map(c => <Select.Option key={c.id}>{c.name}</Select.Option>)
            .concat(<Select.Option key={'_remove'}><em><DeleteOutlined/> {t('remove-creator-tag','Remove Creator Tag')}</em></Select.Option>)

    const onSearch = (val)=> {
        setLoading(true)
        setValue(val)

        api(`/api/creator_tags?q=${val}`).then(res => {
            setLoading(false)
            setCreatorTags(res.data)
        })
    }

    const assetsDispatch = useAssetsDispatch();

    const select = (id, opt) => {
        update(id == '_remove' ? '_remove' : opt.children)
    }

    const [updating, setUpdating] = useState(false)

    const update = (creator_tag_name)=> {
        console.log(creator_tag_name)
        setUpdating(true)
        api.put(`/api/assets/${asset.id}`, {asset: {creator_tag_name}}).then(res => {
            setUpdating(false)
            setVisible(false)
            message.success(t('message-creator-updated','Creator Updated'))
            assetsDispatch({type:'updateAsset', asset: res.data});
            onUpdate(res.data.creator_tag)

            afterUpdate && afterUpdate()
        })
    }

    const keyDown = (e)=> {
        if(e.key == 'Enter') {
            update(value)
        }
    }

    return (
        <>
            {!visible && (
                <Tooltip title={t('tooltip-choose-by-name','Choose by Name')}>
                    <Button type={'link'} size={'small'} icon={<EditOutlined/>} onClick={()=> setVisible(!visible)}>{!asset.creator_tag && placeholder}</Button>
                </Tooltip>
            )}

            {visible && (
                <Space>
                    <Select
                        style={{width: width || 300}}
                        showSearch
                        loading={loading || updating}
                        defaultActiveFirstOption={false}
                        placeholder={t('placeholder-search-or-add-new','Search or add new...')}
                        filterOption={false}
                        onSearch={onSearch}
                        notFoundContent={null}
                        onSelect={select}
                        onInputKeyDown={keyDown}
                        autoFocus
                    >
                        {options}
                    </Select>

                    <Tooltip title={t('tooltip-cancel','Cancel')}>
                        <Button icon={<CloseOutlined/>} onClick={()=> setVisible(false)} size={'small'}/>
                    </Tooltip>
                </Space>
            )}
        </>
    )

}

export {CreatorTag, CreatorTagSelect}
import React, {useContext, useEffect, useState} from 'react';
// import { connect } from 'react-redux';

import {Link, NavLink, useLocation, withRouter} from "react-router-dom";

import {Layout, Menu, Breadcrumb, Input, Tooltip, Divider, Drawer, Button} from 'antd';
const { Content, Sider, Footer } = Layout;

import AppHeader from '../AppHeader';
import {isMobile, isBrowser, MobileView} from "device-detect";

import {
    ApartmentOutlined,
    CloudUploadOutlined, CompassOutlined, CreditCardOutlined,
    DeploymentUnitOutlined, FileOutlined, GlobalOutlined, ImportOutlined, InboxOutlined, LineChartOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined, ProfileOutlined, ReconciliationOutlined, SettingOutlined,
    TeamOutlined,
    UserOutlined, ApiOutlined, FieldTimeOutlined, FormOutlined
} from '@ant-design/icons';
import Icon from '@ant-design/icons';
import {useStorageState} from "react-storage-hooks";
import {CollectionIcon, CustomMetaIcon, FaceIcon, ImportIcon, RightsIcon, UserGroupIcon} from "../helpers/icons";
import TagOutlined from "@ant-design/icons/lib/icons/TagOutlined";
import BulbOutlined from "@ant-design/icons/lib/icons/BulbOutlined";
import OrgNavLink, {useOrgPath} from "../helpers/OrgNavLink";
import SiteSearch from "../widgets/SiteSearch";
import ExploreLeftSideBar from "../explore/ExploreLeftSideBar";
import {AbilityContext, Can} from "../helpers/Can";
import LegalFooter from "../widgets/LegalFooter";
import useCurrentOrg from "../helpers/useCurrentOrg";
import {useAssetGroupDispatch} from "../../contexts/AssetGroupContext";
import {useTranslation} from "react-i18next";
import BrandedButton from "../widgets/BrandedButton";
import useCurrentUser from "@/components/helpers/useCurrentUser";
import {useNavigate} from "react-router-dom-v5-compat";

const ManageLayout = withRouter(props => {
    const {t} = useTranslation();
    const [{collapsed}, setState] = useStorageState(sessionStorage, 'manage-sider-collapse', {});

    const currentUser = useCurrentUser()

    const navigate = useNavigate()
    const orgPath = useOrgPath()

    useEffect(()=>{
      if(!currentUser) navigate(orgPath('/login'))
    }, []);

    return (
        <Layout>
            <AppHeader/>
            <Layout>
                {!isMobile() && (
                    <Sider width={200} style={{background: '#fff'}} trigger={null} collapsible collapsed={collapsed}>
                        <ManageMenu collapsed={collapsed} setState={setState}/>
                    </Sider>
                )}

                <Layout>
                    <Content
                        style={{
                            background: '#fff',
                            padding: '0 1em 1em 1em',
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                            margin: 0,
                            minHeight: '100vh',
                        }}
                    >

                        <main role={'main'} aria-label={t('organization-manage-label', 'Organization Manage')} tabIndex={0}>
                            {props.children}
                        </main>

                        <LegalFooter/>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
);
});

export default ManageLayout;

const ManageMenu = ({
    collapsed, setState, children
})=> {
    const {t} = useTranslation();
    const location = useLocation()

    const ability = useContext(AbilityContext);

    const org = useCurrentOrg()

    const inner = (
        <Menu
            mode="inline"
            selectedKeys={_.last(location.pathname.split('/'))}
            style={{height: '100%', borderRight: 0}}
            // style={{height: '100vh', borderRight: 0, overflow:'auto', position:'fixed', left:0}}
        >
            <Menu.Item key="status">
                <OrgNavLink to='/manage/status'>
                    <BulbOutlined/>
                    <span>{t('status','Status')}</span>
                </OrgNavLink>
            </Menu.Item>
            <Menu.Item key="groups">
                <OrgNavLink to='/manage/groups'>
                    <UserGroupIcon/>
                    <span>{t('user-groups','User Groups')}</span>
                </OrgNavLink>
            </Menu.Item>
            <Menu.Item key="members">
                <OrgNavLink to='/manage/members'>
                    <TeamOutlined/>
                    <span>{t('members','Members')}</span>
                </OrgNavLink>
            </Menu.Item>
            {ability.can('view_manage_uploads', 'Organization') && (
                <Menu.Item key="uploads">
                    <OrgNavLink to='/manage/uploads'>
                        <CloudUploadOutlined/>
                        <span>{t('uploads','Uploads')}</span>
                    </OrgNavLink>
                </Menu.Item>
            )}
            {ability.can('manage', 'AccessRequest') && (
                <Menu.Item key="access-requests">
                    <OrgNavLink to='/manage/access-requests'>
                        <FormOutlined/>
                        <span>{t('access-requests','Access Requests')}</span>
                    </OrgNavLink>
                </Menu.Item>
            )}
            <Menu.Item key='collections'>
                <OrgNavLink to='/manage/collections'>
                    <CollectionIcon/>
                    <span>{t('collections','Collections')}</span>
                </OrgNavLink>
            </Menu.Item>

            {org?.enable_face_tagging && (
                <Menu.Item key='faces'>
                    <OrgNavLink to='/manage/faces'>
                        <FaceIcon/>
                        <span>{t('faces','Faces')}</span>
                    </OrgNavLink>
                </Menu.Item>
            )}

            <Menu.Item key='taxonomy'>
                <OrgNavLink to='/manage/taxonomy'>
                    <ApartmentOutlined/>
                    <span>{t('keyword-tag-list','Keyword Tag List')}</span>
                </OrgNavLink>
            </Menu.Item>

            <Menu.Item key='creator-tags'>
                <OrgNavLink to='/manage/creator-tags'>
                    <UserOutlined />
                    <span>{t('creator-tags','Creator Tags')}</span>
                </OrgNavLink>
            </Menu.Item>

            {ability.can('view_manage_uploads', 'Organization') && (
                <Menu.Item key='tag-suggesters'>
                    <OrgNavLink to='/manage/tag-suggesters'>
                        <TagOutlined/>
                        <span>{t('tag-suggesters','Tag Suggesters')}</span>
                    </OrgNavLink>
                </Menu.Item>
            )}
            {ability.can('manage', 'Organization') && (
                <Menu.Item key="rights">
                    <OrgNavLink to='/manage/rights'>
                        <RightsIcon/>
                        <span>{t('rights-packages','Rights Packages')}</span>
                    </OrgNavLink>
                </Menu.Item>
            )}
            {ability.can('manage', 'CustomMetaField') && (
                <Menu.Item key='custom-meta-fields'>
                    <OrgNavLink to='/manage/custom-meta-fields'>
                        <CustomMetaIcon/>
                        <span>{t('custom-meta-fields','Custom Meta Fields')}</span>
                    </OrgNavLink>
                </Menu.Item>
            )}
            {ability.can('manage', 'MetaImport') && (
                <Menu.Item key='meta-imports'>
                    <OrgNavLink to='/manage/meta-imports'>
                        <ImportIcon/>
                        <span>{t('meta-imports','Meta Imports')}</span>
                    </OrgNavLink>
                </Menu.Item>
            )}
            {ability.can('manage', 'Organization') && (
                <Menu.Item key="site-settings">
                    <OrgNavLink to='/manage/site-settings'>
                        <GlobalOutlined/>
                        <span>{t('site-settings','Site Settings')}</span>
                    </OrgNavLink>
                </Menu.Item>
            )}
            <Menu.Item key="integrations">
                <OrgNavLink to='/manage/integrations'>
                    <ApiOutlined/>
                    <span>{t('integrations','Integrations')}</span>
                </OrgNavLink>
            </Menu.Item>
            <Menu.Item key="reports">
                <OrgNavLink to='/manage/reports'>
                    <LineChartOutlined/>
                    <span>{t('reports','Reports')}</span>
                </OrgNavLink>
            </Menu.Item>

            {ability.can('manage', 'Organization') && (
                <Menu.Item key="billing">
                    <OrgNavLink to='/manage/billing'>
                        <CreditCardOutlined/>
                        <span>{t('plan-and-billing','Plan and Billing')}</span>
                    </OrgNavLink>
                </Menu.Item>
            )}

            {/*<Menu.ItemGroup title={<small><em>Coming Soon:</em></small>}>*/}
            {/*    /!*<Menu.Item key="1">*!/*/}
            {/*    /!*    <UserOutlined/>*!/*/}
            {/*    /!*    <span>Profile</span>*!/*/}
            {/*    /!*</Menu.Item>*!/*/}
            {/*    <Menu.Item key="2">*/}
            {/*        <ProfileOutlined/>*/}
            {/*        <span>Account</span>*/}
            {/*    </Menu.Item>*/}
            {/*    <Menu.Item key="8">*/}
            {/*        <FileOutlined/>*/}
            {/*        <span>Assets</span>*/}
            {/*    </Menu.Item>*/}
            {/*</Menu.ItemGroup>*/}

            {setState && (
                <Menu.Item key={'hide'}>
                    <Tooltip title={!collapsed && t('hide-menu','Hide Menu')} placement={'right'}>
                        <a onClick={() => setState({collapsed: !collapsed})}>
                            {collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                            {collapsed && <span>{t('show-menu','Show Menu')}</span>}
                        </a>
                    </Tooltip>
                </Menu.Item>
            )}
        </Menu>
    )

    const [drawerVisible, setDrawerVisible] = useState()

    if(isBrowser()) return inner;

    if(isMobile()) return (<>
        <BrandedButton icon={<SettingOutlined/>} type={'primary'} onClick={()=> setDrawerVisible(true)} shape={'circle'}/>
        <Drawer
            placement={'left'}
            onClose={() => setDrawerVisible(false)}
            onClick={() => setDrawerVisible(false)}
            open={drawerVisible}
            width={'75%'}
            bodyStyle={{padding: 0, margin: '.5em .5em 0 .5em'}}
            id={'manage-drawer'}
            title={<><SettingOutlined /> {t('manage','Manage')}</>}
        >
            {inner}
        </Drawer>
    </>);
}

export {ManageMenu}
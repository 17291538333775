import React, {useState, useEffect} from "react";
import {Badge, Button, Flex, List, Popover, Space, Tag, Tooltip, Typography, Dropdown} from "antd";
import {
    BoxPlotOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined, CloudServerOutlined,
    ColumnWidthOutlined,
    DeleteOutlined, DownloadOutlined,
    EditOutlined,
    ExpandOutlined,
    EyeInvisibleOutlined,
    FileTextOutlined,
    FilterOutlined,
    GlobalOutlined, HeartOutlined, LoadingOutlined, LogoutOutlined,
    PushpinOutlined, QuestionOutlined,
    ReconciliationOutlined,
    SaveOutlined,
    SearchOutlined,
    StarOutlined,
    TagOutlined,
    UploadOutlined,
    UserOutlined
} from "@ant-design/icons";
import FileOutlined from "@ant-design/icons/lib/icons/FileOutlined";
import {
    BlockDownload, BlockView, CollectionIcon,
    CustomMetaIcon, FrameIcon, LightboxIcon,
    RightsIcon, StorageFolderIcon,
    TaxonomyIcon,
    ThumbsDownIcon,
    ThumbsUpIcon,
    WorkflowIcon
} from "../helpers/icons";
import {useFilters} from "../helpers/useFilters";
import api from "../api";
import moment from "moment";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {RequiredIcon} from "../widgets/EditAssetTable";
import {useTranslation} from "react-i18next";
import VerticalSpace from "~/components/helpers/VerticalSpace";
import { CreatorTag } from "./CreatorTagList";

export default () => {
    const {t} = useTranslation();
    const {filters, clearFilters, setFilters, filterCount} = useFilters();
    // console.log('filters', filters)

    const [taxonomyTags, setTaxonomyTags] = useState([])

    useEffect(()=>{
        const paths = [...filters.taxonomy, ...filters.hide_taxonomy]
        if(!paths.length) return;

        api.post('/api/taxonomy_tags/bulk_find', {ids: paths}).then(res => {
            setTaxonomyTags(res.data);
        })
    }, [filters.taxonomy.length, filters.hide_taxonomy.length])

    const [rights, setRights] = useState([])

    useEffect(()=>{
        if(!filters.rights.length) return;

        api.post('/api/rights_packages/bulk_find', {ids: filters.rights}).then(res => {
            if(filters.rights.indexOf('missing') != -1) {
                res.data = res.data.concat({id:'missing', name: 'Missing'})
            }

            setRights(res.data);
        })
    }, [filters.rights.length])

    const [filtersVisible, setFiltersVisible] = useState()
    const toggleFilters = ()=>{
        setFiltersVisible(!filtersVisible)
    }

    const visibleProp = isMobile() ? {visible: filtersVisible} : {}

    if(!filterCount) return '';

    return (
        <>
            <Dropdown.Button
                size={'small'}
                danger
                onClick={isMobile() ? toggleFilters : clearFilters}
                menu={{items:[]}}
                buttonsRender={([left, right])=> [left,
                    <Popover
                        getPopupContainer={e => e.parentElement}
                        trigger={['hover','click']}
                        title={
                            <>
                                <FilterOutlined/> {t('applied-filters','Applied Filters')}
                                {isMobile() && <div style={{float:'right'}}><Button onClick={toggleFilters} size={'small'} type={'text'}>X</Button></div>}
                            </>
                        }
                        placement='right'
                        {...visibleProp}
                        content={
                            <VerticalSpace>
                                {isMobile() && (
                                    <Button onClick={clearFilters} size={'small'} danger ghost block><DeleteOutlined/> {t('button-clear-all','Clear All')}</Button>
                                )}

                                {filters.sql && <p><RemoveButton filter={'sql'}/>{t('boolean-query','Boolean Query')}: <Typography.Text code>{filters.sql}</Typography.Text></p>}

                                {filters.q && filters.q != '' && (
                                    <p>
                                        <RemoveButton filter={'q'}/>
                                        <SearchOutlined/> {t('search','search')}: <Typography.Text code>{filters.q}</Typography.Text>
                                    </p>
                                )}

                                {filters.custom_meta && Object.keys(filters.custom_meta).map(key => {
                                    const value = filters.custom_meta[key]
                                    return (
                                        <p>
                                            <RemoveButton filter={'custom_meta'} objectKey={key}/> <CustomMetaIcon/> {key}: {value}
                                        </p>
                                    );
                                })}

                                {filters.block_level && (
                                    <p>
                                        <RemoveButton filter={'block_level'}/>
                                        {filters.block_level == '0' && <>{t('no-restricted-access','No Restricted Access')}</>}
                                        {filters.block_level == '1' && <><BlockDownload/> {t('blocked-download','Blocked Download')}</>}
                                        {filters.block_level == '2' && <><BlockView/> {t('blocked-view-and-download','Blocked View and Download')}</>}
                                    </p>
                                )}

                                {filters.frame_status && filters.frame_status != '' && <p><RemoveButton filter={'frame_status'}/> <FrameIcon/> {t('frame-status','Frame.io Status')}: {t(`frame-status-${filters.frame_status}`, filters.frame_status.toProperCase().replace('_', ' '))} </p>}

                                {filters.creator_tag_id && filters.creator_tag_id != '' && <p><RemoveButton filter={'creator_tag_id'} array/> <UserOutlined/> {t('creator','Creator')}: <CreatorTag cTag={{id: filters.creator_tag_id}} /></p>}
                                {filters.unsaved_creator && filters.unsaved_creator != '' && <p><RemoveButton filter={'unsaved_creator'} array/> <UserOutlined/> {t('unsaved-creator','Unsaved Creator')}</p>}
                                {filters.no_creator && <p><RemoveButton filter={'no_creator'}/> <QuestionOutlined/> {t('no-creator','No Creator')}</p>}
                                {filters.vote && <p><RemoveButton filter={'vote'}/> {filters.vote == '1' ? <ThumbsUpIcon/> : <ThumbsDownIcon/>}</p>}
                                {filters.watermark && <p><RemoveButton filter={'watermark'}/> <DownloadOutlined/> {t('downloadable-file-watermark','Downloadable File Watermark')}: {t(filters.watermark,filters.watermark.toProperCase())}</p>}
                                {filters.aasm_state && <p><RemoveButton filter={'aasm_state'}/> {t('state','State')}: {t(filters.aasm_state,filters.aasm_state)}</p>}

                                {filters.contracts && filters.contracts != '' && <p><RemoveButton filter={'contracts'}/><FileTextOutlined/> {t('contracts','Contracts')}</p>}
                                {filters.releases && filters.releases != '' && <p><RemoveButton filter={'releases'}/><FileTextOutlined/> {t('releases','Releases')}</p>}

                                {filters.contract_asset_id && filters.contract_asset_id != '' && <p><RemoveButton filter={'contract_asset_id'} array/><FileTextOutlined/> {t('contract-assets','Contract Assets')}</p>}
                                {filters.release_ids && filters.release_ids != '' && <p><RemoveButton filter={'release_ids'} array/><FileTextOutlined/> {t('release-assets','Release Assets')}</p>}

                                {filters.expired && filters.expired != '' && <p><RemoveButton filter={'expired'}/><ClockCircleOutlined/> {t('expired','Expired')}</p>}

                                {filters.nsfw_detected && filters.nsfw_detected != '' && <p><RemoveButton filter={'nsfw_detected'}/><EyeInvisibleOutlined/> {t('nsfw','NSFW')}</p>}
                                {filters.nsfw_cleared && filters.nsfw_cleared != '' && <p><RemoveButton filter={'nsfw_cleared'}/><EyeInvisibleOutlined/> {t('nsfw-cleared','NSFW Cleared')}</p>}
                                {filters.auto_tagged && filters.auto_tagged != '' && <p><RemoveButton filter={'auto_tagged'}/><CloudServerOutlined/> {t('auto-tagged','Auto-Tagged')}: {t(filters.auto_tagged,filters.auto_tagged)}</p>}
                                {filters.faces_indexed && filters.faces_indexed != '' && <p><RemoveButton filter={'faces_indexed'}/><UserOutlined/> {t('faces-analyzed','Faces Analyzed')}: {t(filters.faces_indexed,filters.faces_indexed)}</p>}

                                {filters.missing_captured_at && filters.missing_captured_at != '' && <p><RemoveButton filter={'missing_captured_at'}/> <ClockCircleOutlined/> {t('missing-create-date','Missing Create Date')}</p>}
                                {filters.unidentified_faces && filters.unidentified_faces != '' && <p><RemoveButton filter={'unidentified_faces'}/> <UserOutlined/> {t('has-unidentified-faces','Has Unidentified Faces')}</p>}
                                {filters.faces_not_search && filters.faces_not_search != '' && <p><RemoveButton filter={'faces_not_searched'}/> <UserOutlined/> {t('faces-not-searched','Faces Not Searched')}</p>}
                                {filters.has_people && filters.has_people != '' && <p><RemoveButton filter={'has_people'}/> <UserOutlined/> {t('has-people','Has People')}: {t(filters.has_people,filters.has_people)}</p>}
                                {filters.unsubmitted && filters.unsubmitted != '' && <p><RemoveButton filter={'unsubmitted'}/> <RequiredIcon/> {t('unsubmitted','Unsubmitted')}: {t(filters.unsubmitted,filters.unsubmitted)}</p>}
                                {filters.optimization_request && filters.optimization_request != '' && <p><RemoveButton filter={'optimization_request'}/> <ReconciliationOutlined/> {t('optimization request','Optimization Request')}: {t(filters.optimization_request,filters.optimization_request)}</p>}
                                {filters.checkout && filters.checkout != '' && <p><RemoveButton filter={'checkout'}/> <LogoutOutlined/> {t('checkout','Checkout')}: {t(filters.checkout,filters.checkout)}</p>}
                                {filters.has_gps && filters.has_gps != '' && <p><RemoveButton filter={'has_gps'}/> <PushpinOutlined/> {t('has-gps','Has GPS')}: {t(filters.has_gps,filters.has_gps)}</p>}
                                {filters.has_alt_text && filters.has_alt_text != '' && <p><RemoveButton filter={'has_alt_text'}/> <FileTextOutlined/> {t('has-alt-text','Has Alt Text')}: {t(filters.has_alt_text,filters.has_alt_text)}</p>}
                                {filters.submitted && filters.submitted != '' && <p><RemoveButton filter={'submitted'}/> <CheckCircleOutlined/> {t('submitted','Submitted')}: {t(filters.submitted,filters.submitted)}</p>}
                                {filters.file_size_range && filters.file_size_range != '' && <p><RemoveButton filter={'file_size_range'}/> <SaveOutlined/> {t('file-size','File Size')}: {filters.file_size_range} MB</p>}

                                {filters.bounds && filters.bounds != '' && <p><RemoveButton filter={'bounds'}/> <GlobalOutlined/> {t('map-bounds','Map Bounds')}</p>}

                                {filters.aspect && filters.aspect != '' && <p><RemoveButton filter={'aspect'}/> <ExpandOutlined /> {t('aspect-ratio','Aspect Ratio')}: {t(filters.aspect,filters.aspect.toProperCase())} </p>}

                                {filters.proximity_word_1 && filters.proximity_word_1 != '' && <p><RemoveButton filter={['proximity_word_1', 'proximity_word_2', 'proximity_max_gaps', 'proximity_field']}/> <ColumnWidthOutlined/> {t('word-proximity','Word Proximity')} ({filters.proximity_field}): "{filters.proximity_word_1}" {t('and','and')} "{filters.proximity_word_2}", {t('max-gaps','Max Gaps')}: {filters.proximity_max_gaps} </p>}

                                <AssetGroupFilter f={'storage_folder_ids'}/>
                                <AssetGroupFilter f={'collection_ids'}/>
                                <AssetGroupFilter f={'lightbox_ids'}/>

                                {!!filters.taxonomy.length && !!taxonomyTags.length && (
                                    <List
                                        size="small"
                                        dataSource={filters.taxonomy}
                                        header={<><TaxonomyIcon/> {t('has-tag','Has tag')}</>}
                                        renderItem={path => {
                                            const getPath = taxTag => taxTag.ancestor_ids.concat(taxTag.id).join('/');

                                            const taxTag = _.find(taxonomyTags, taxTag => path == getPath(taxTag))

                                            if(!taxTag) return <></>

                                            const remove = ()=> {
                                                setFilters(true, {taxonomy: getPath(taxTag)})
                                            }

                                            return (
                                                <List.Item>
                                                    <RemoveButton onClick={remove}/>

                                                    {taxTag.tag.person ? <UserOutlined/> : <TagOutlined/>}
                                                    &nbsp;
                                                    {taxTag.path_names.join(' / ')}
                                                </List.Item>
                                            );
                                        }}
                                    />
                                )}

                                {!!filters.hide_taxonomy.length && !!taxonomyTags.length && (
                                    <List
                                        size="small"
                                        dataSource={filters.hide_taxonomy}
                                        header={<><TaxonomyIcon/> {t('does-not-have-tag','Does not have tag')} <EyeInvisibleOutlined/></>}
                                        renderItem={path => {
                                            const getPath = taxTag => taxTag.ancestor_ids.concat(taxTag.id).join('/');

                                            const taxTag = _.find(taxonomyTags, taxTag => path == getPath(taxTag))

                                            if(!taxTag) return <></>

                                            const remove = ()=> {
                                                setFilters(true, {hide_taxonomy: getPath(taxTag)})
                                            }

                                            return (
                                                <List.Item>
                                                    <RemoveButton onClick={remove}/>

                                                    {taxTag.tag.person ? <UserOutlined/> : <TagOutlined/>}
                                                    &nbsp;
                                                    {taxTag.path_names.join(' / ')}
                                                </List.Item>
                                            );
                                        }}
                                    />
                                )}

                                {!!filters.rights.length && (
                                    <List
                                        header={<><RightsIcon/> {t('rights-filtered','Rights Filtered')}</>}
                                        size="small"
                                        dataSource={rights}
                                        renderItem={right => {
                                            const remove = ()=> {
                                                setFilters(true, {rights: right.id.toString()})
                                            }

                                            return (
                                                <List.Item>
                                                    <RemoveButton onClick={remove}/>
                                                    &nbsp;
                                                    {right.name}
                                                </List.Item>
                                            );
                                        }}
                                    />
                                )}

                                {!!filters.rights_code.length && (
                                    <List
                                        header={<><RightsIcon/> {t('rights-class-filtered','Rights Class Filtered')}</>}
                                        size="small"
                                        dataSource={filters.rights_code}
                                        renderItem={code => {
                                            const remove = ()=> {
                                                setFilters(true, {rights_code: code})
                                            }

                                            return (
                                                <List.Item>
                                                    <RemoveButton onClick={remove}/>
                                                    &nbsp;
                                                    {t(code,code.toProperCase())}
                                                </List.Item>
                                            );
                                        }}
                                    />
                                )}

                                {!!filters.tags.length && (
                                    <List
                                        header={<><TagOutlined/> {t('keywords','Keywords')}</>}
                                        size="small"
                                        dataSource={filters.tags?.filter(obj => obj)}
                                        renderItem={item => {
                                            const remove = ()=> {
                                                setFilters(true, {tags: item})
                                            }

                                            return (
                                                <List.Item>
                                                    <RemoveButton onClick={remove}/>
                                                    <TagOutlined/>
                                                    &nbsp;
                                                    {item}
                                                </List.Item>
                                            );
                                        }}
                                    />
                                )}

                                {!!filters.face_tags.length && (
                                    <List
                                        header={<><UserOutlined/> {t('faces','Faces')}</>}
                                        size="small"
                                        dataSource={filters.face_tags?.filter(obj => obj)}
                                        renderItem={item => {
                                            const remove = ()=> {
                                                setFilters(true, {face_tags: item})
                                            }

                                            return (
                                                <List.Item>
                                                    <RemoveButton onClick={remove}/>
                                                    <UserOutlined/>
                                                    &nbsp;
                                                    {item}
                                                </List.Item>
                                            );
                                        }}
                                    />
                                )}

                                {!!filters.auto_tags.length && (
                                    <List
                                        header={<><TagOutlined/> {t('auto-keywords','Auto-Keywords')}</>}
                                        size="small"
                                        dataSource={filters.auto_tags?.filter(obj => obj)}
                                        renderItem={item => {
                                            const remove = ()=> {
                                                setFilters(true, {auto_tags: item})
                                            }

                                            return (
                                                <List.Item>
                                                    <RemoveButton onClick={remove}/>
                                                    <TagOutlined/> {t('auto-tag','Auto Tag')}: {item}
                                                </List.Item>
                                            );
                                        }}
                                    />
                                )}

                                {!!filters.hide_tags.length && (
                                    <List
                                        header={<><TagOutlined/> {t('hidden-keywords','Hidden Keywords')}</>}
                                        size="small"
                                        dataSource={filters.hide_tags?.filter(obj => obj)}
                                        renderItem={item => {
                                            const remove = ()=> {
                                                setFilters(true, {hide_tags: item})
                                            }

                                            return (
                                                <List.Item>
                                                    <RemoveButton onClick={remove}/>
                                                    <TagOutlined/>
                                                    &nbsp;
                                                    {item} <EyeInvisibleOutlined/>
                                                </List.Item>
                                            );
                                        }}
                                    />
                                )}

                                {!!filters.hide_auto_tags.length && (
                                    <List
                                        header={<><TagOutlined/> {t('hidden-auto-tags','Hidden Auto Tags')}</>}
                                        size="small"
                                        dataSource={filters.hide_auto_tags?.filter(obj => obj)}
                                        renderItem={item => {
                                            const remove = ()=> {
                                                setFilters(true, {hide_auto_tags: item})
                                            }

                                            return (
                                                <List.Item>
                                                    <RemoveButton onClick={remove}/>
                                                    <TagOutlined/>
                                                    &nbsp;
                                                    {item} <EyeInvisibleOutlined/>
                                                </List.Item>
                                            );
                                        }}
                                    />
                                )}

                                {!!filters.hide_face_tags.length && (
                                    <List
                                        header={<><TagOutlined/> {t('hidden-face-tags','Hidden Face Tags')}</>}
                                        size="small"
                                        dataSource={filters.hide_face_tags?.filter(obj => obj)}
                                        renderItem={item => {
                                            const remove = ()=> {
                                                setFilters(true, {hide_face_tags: item})
                                            }

                                            return (
                                                <List.Item>
                                                    <RemoveButton onClick={remove}/>
                                                    <TagOutlined/>
                                                    &nbsp;
                                                    {item} <EyeInvisibleOutlined/>
                                                </List.Item>
                                            );
                                        }}
                                    />
                                )}

                                {!!filters.moderation_names.length && (
                                    <List
                                        header={<><EyeInvisibleOutlined/> {t('nsfw','NSFW')}</>}
                                        size="small"
                                        dataSource={filters.moderation_names?.filter(obj => obj)}
                                        renderItem={item => {
                                            const remove = ()=> {
                                                setFilters(true, {moderation_names: item})
                                            }

                                            return (
                                                <List.Item>
                                                    <RemoveButton onClick={remove}/> {item}
                                                </List.Item>
                                            );
                                        }}
                                    />
                                )}

                                {!!filters.exts.length && (
                                    <List
                                        header={<><FileOutlined/> {t('file-types','File Types')}</>}
                                        size="small"
                                        dataSource={filters.exts}
                                        renderItem={item => {
                                            const remove = ()=> {
                                                setFilters(true, {exts: item})
                                            }

                                            return (
                                                <List.Item>
                                                    <RemoveButton onClick={remove}/>
                                                    {item}
                                                </List.Item>
                                            );
                                        }}
                                    />
                                )}

                                {!!filters.download_levels.length && (
                                    <List
                                        header={<><DownloadOutlined/> {t('download-access','Download Access')}</>}
                                        size="small"
                                        dataSource={filters.download_levels}
                                        renderItem={item => {
                                            const remove = ()=> {
                                                setFilters(true, {download_levels: item})
                                            }

                                            return (
                                                <List.Item>
                                                    <RemoveButton onClick={remove}/>
                                                    {item.toProperCase()}
                                                </List.Item>
                                            );
                                        }}
                                    />
                                )}

                                {!!filters.rating.length && (
                                    <p>
                                        <StarOutlined/> {t('rating','Rating')}:
                                        <br/>
                                        <RemoveButton filter={'rating'} range/>
                                        {filters.rating[0]} - {filters.rating[1]}
                                    </p>
                                )}

                                {!!filters.captured_at.length && (
                                    <p>
                                        <ClockCircleOutlined/> {t('date-created','Date Created')}:
                                        <br/>
                                        <RemoveButton filter={'captured_at'} range/>
                                        {moment.utc(filters.captured_at[0]).format('LL')} - {moment.utc(filters.captured_at[1]).format('LL')}
                                    </p>
                                )}

                                {!!filters.created_at.length && (
                                    <p>
                                        <UploadOutlined/> {t('upload-date','Upload Date')}:
                                        <br/>
                                        <RemoveButton filter={'created_at'} range/>
                                        {moment.utc(filters.created_at[0]).format('LL')} - {moment.utc(filters.created_at[1]).format('LL')}
                                    </p>
                                )}

                                {!!filters.updated_at.length && (
                                    <p>
                                        <EditOutlined/> {t('last-update-date','Last Update Date')}:
                                        <br/>
                                        <RemoveButton filter={'updated_at'} range/>
                                        {moment.utc(filters.updated_at[0]).format('LL')} - {moment.utc(filters.updated_at[1]).format('LL')}
                                    </p>
                                )}

                                {!!filters.user_ids.length && (
                                    <p>
                                        <RemoveButton filter={'user_ids'} array/>
                                        <UserOutlined/> {t('uploaded-by-user-filter','Uploaded By User Filter')}
                                    </p>
                                )}

                                {!!filters.workflow_state.length && (
                                    <List
                                        header={<><WorkflowIcon/> {t('workflow','Workflow')}</>}
                                        size="small"
                                        dataSource={filters.workflow_state}
                                        renderItem={item => {
                                            const remove = ()=> {
                                                setFilters(true, {workflow_state: item})
                                            }

                                            return (
                                                <List.Item>
                                                    <RemoveButton onClick={remove}/>
                                                    {t(item,item.toProperCase())}
                                                </List.Item>
                                            );
                                        }}
                                    />
                                )}
                            </VerticalSpace>
                        }
                    >
                        {right}
                    </Popover>
                ]}
            >
                <Tooltip placement={'top'} title={isBrowser() && t('tooltip-clear-all','Clear All')} trigger={['hover','focus']}>
                    <Flex align={'center'}>
                        <FilterOutlined/>
                        {isBrowser() && t('filters','Filters')}
                        <Badge style={{marginLeft: isBrowser() && '.5em', marginRight:0}} count={filterCount}/>
                    </Flex>
                </Tooltip>
            </Dropdown.Button>
        </>
    )
}

const RemoveButton = ({onClick, filter, array, range, objectKey})=> {
    const {t} = useTranslation();
    const {setFilters, filters} = useFilters();

    const remove = ()=> {
        const newFilters = {}
        if(typeof filter == 'object') {
            filter.map(f => newFilters[f] = null)
        } else if(objectKey) {
            delete filters[filter][objectKey]
            newFilters[filter] = filters[filter]
        } else {
            newFilters[filter] = array ? filters[filter][0] : (range ? [] : null)
        }
        setFilters(true, newFilters)
    }

    return (
        <Tooltip title={t('tooltip-remove-filter','Remove Filter')} placement={'left'}>
            <Button
                danger
                icon={<CloseCircleOutlined/>}
                type={'text'}
                size={'small'}
                style={{marginRight:'.5em', marginLeft:0}}
                onClick={onClick || remove}
                tabIndex={0}
            />
        </Tooltip>
    )
}

const AssetGroupFilter = ({f})=>{
    const {t} = useTranslation();
    const {filters} = useFilters();
    const [assetGroup, setAssetGroup] = useState()

    const id = filters[f]

    let typeName;
    let path;
    let icon;
    switch(f) {
        case 'storage_folder_ids':
            typeName = 'Storage Folder'
            path = 'storage_folders'
            icon = <StorageFolderIcon/>
            break
        case 'collection_ids':
            typeName = 'Collection'
            path = 'collections'
            icon = <CollectionIcon/>
            break
        case 'lightbox_ids':
            typeName = 'Lightbox'
            path = 'lightboxes'
            icon = <LightboxIcon/>
            break
    }

    useEffect(()=>{
        if(!id || id === '') return;

        api(`/api/${path}/${id}`).then(res => {
            setAssetGroup(res.data)
        })

    }, [id])

    if(!id || id === '') return <></>

    return (
        <p><RemoveButton filter={f}/><strong>{t(typeName,typeName)}:</strong> {icon} {assetGroup ? assetGroup.name : <LoadingOutlined/>}</p>
    )
}